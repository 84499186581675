import React, { useState } from "react";
import { useIntl } from "react-intl";

import { Community_liveness } from "./Community_liveness";
import Referrals_Miner from "./Referrals_Miner";
import { CommunityWrapper } from "../styles/Community";
import { ReferralsBanner } from "./ReferralsBanner";

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

interface IProps {
  CNTP: string;
  setCNTP: (v: string) => void;
  setTodayCNTP: (v: string) => void;
}

export const Community: React.FC<IProps> = ({
  CNTP,
  setCNTP,
  setTodayCNTP,
}) => {
  const intl = useIntl();
  const [value, setValue] = React.useState(0);
  const [animei, setAnimei] = useState<"left" | "right">("left");
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    const old = value;
    setValue(newValue);
    if (newValue > old) {
      return setAnimei("right");
    }
    setAnimei("left");
  };

  return (
    <CommunityWrapper>
      <Community_liveness
        CNTP={CNTP}
        setCNTP={setCNTP}
        setTodayCNTP={setTodayCNTP}
      />
      <Referrals_Miner CNTP={CNTP} />
      {/* <ReferralsBanner /> */}
    </CommunityWrapper>
  );
};
