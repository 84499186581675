import React, { useEffect, useState } from 'react';
import WbSunnyOutlinedIcon from '@mui/icons-material/WbSunnyOutlined';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import { Box } from '@mui/material';
import useAppState from '../../../../../store/appState/useAppState';
import { useColorScheme } from '@mui/material-next';
import {savePreferences} from "../../../../../services/workerService/workerService"
import { useTranslation } from 'react-i18next';

/* const LightModeComponent = ({ isActive, onClick }: any) => (
  
  <div
    style={{
      display: 'flex',
      zIndex: 1,
      width: '94px',
      maxHeight: '40px',
      background: isActive ? '#79F8FF' : 'transparent',
      textAlign: 'center',
      padding: '8px 16px',
      borderRadius: '16px',
      cursor: 'pointer',
      gap: '8px'
    }}
    onClick={onClick}
  >
    <WbSunnyOutlinedIcon />
    Light
  </div>
);

const DarkModeComponent = ({ isActive, onClick }: any) => (
  <div
    style={{
      display: 'flex',
      zIndex: 1,
      width: '94px',
      maxHeight: '40px',
      color: isActive ? 'white' : 'black',
      background: isActive ? '#D775FF' : 'transparent',
      textAlign: 'center',
      padding: '8px 16px',
      borderRadius: '16px',
      cursor: 'pointer',
      gap: '8px'
    }}
    onClick={onClick}
  >
    <DarkModeOutlinedIcon style={{ transform: 'rotate(-90deg)' }} />
    Dark
  </div>
); */


const ThemeSwitcher = () => {
  const { mode, setMode } = useColorScheme();
  const {
    locale,
    setDAPPOpen,
    setTheme,
    theme,
  } = useAppState();
  const [isDarkMode, setIsDarkMode] = useState(false);
  const { t } = useTranslation()
  const LightModeComponent = ({ isActive, onClick }: any) => (
  
    <div
      style={{
        display: 'flex',
        zIndex: 1,
        width: '120px',
        maxHeight: '40px',
        background: isActive ? '#79F8FF' : 'transparent',
        textAlign: 'center',
        padding: '8px 16px',
        borderRadius: '16px',
        cursor: 'pointer',
        gap: '8px'
      }}
      onClick={onClick}
    >
      <WbSunnyOutlinedIcon />
      {t('settings.light')}
    </div>
  );
  
  const DarkModeComponent = ({ isActive, onClick }: any) => (
    <div
      style={{
        display: 'flex',
        zIndex: 1,
        width: '120px',
        maxHeight: '40px',
        color: isActive ? 'white' : 'black',
        background: isActive ? '#D775FF' : 'transparent',
        textAlign: 'center',
        padding: '8px 16px',
        borderRadius: '16px',
        cursor: 'pointer',
        gap: '8px'
      }}
      onClick={onClick}
    >
      <DarkModeOutlinedIcon style={{ transform: 'rotate(-90deg)' }} />
      {t('settings.dark')}
    </div>
  );

  useEffect(() => {
    setIsDarkMode(localStorage.getItem('mui-mode') === 'dark');
  }, [isDarkMode]);

  const handleModeToggle = () => {
    
    // setIsDarkMode(prevMode => !prevMode);
    if (isDarkMode) {
      setMode(`light`);
      setTheme("Light");
    } else {
      setMode(`dark`);
      setTheme("Dark");
    }

    setIsDarkMode(!isDarkMode)
  };

  return (
    <Box position="relative" bgcolor={!isDarkMode ? "#F6F8FF" : "#363E59"} borderRadius="16px">
      <Box display="flex" justifyContent="space-around">
        <LightModeComponent isActive={!isDarkMode} onClick={handleModeToggle} />
        <DarkModeComponent isActive={isDarkMode} onClick={handleModeToggle} />
      </Box>
    </Box>
  );
};

export default ThemeSwitcher;
