import styled from "styled-components";
import referralBg from "../../../../../assets/referralBg.png";

export const ReferralsMinerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  /* padding: 3rem 0; */
  max-width: 1040px;
  width: 100%;
  margin: auto;
  position: relative;
  padding: 5rem 0;

  @media (max-width: 600px) {
    padding: 5rem 16px;
  }
  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }

  @media (max-width: 900px) {
    .bg-icon {
      display: none;
    }
  }
`;

export const ReferralsMinerInviteCard = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  gap: 32px;
  border-radius: 1rem;
  padding: 1.5rem;
  max-width: 30rem;
  width: 100%;
  z-index: 2;
  background-color: ${localStorage.getItem("mui-mode") === "light"
    ? "#FFFFFF"
    : "#1b1b1d"};
  @media (max-width: 600px) {
    width: 100%;
    margin: auto;
  }
`;
