import { Typography, SvgIcon, Stack, Box, Checkbox, Select, MenuItem, Skeleton, ListSubheader, CircularProgress, InputLabel, Link } from "@mui/material";
import img1 from "../../miner/assets/guardianEnd.svg";
import img5 from "../../miner/assets/sucess-transaction-icon.png"
import conetLogo3d from '../../miner/assets/CONET LOGO 3D 5.png'
import dentro from "../../../../../assets/images/dentro.svg"
import fora from "../../../../../assets/images/fora.svg"
import failure from "../../../../../assets/images/failure.png";

import { totalmem } from "os";
import { PurchaseButtom } from "../../miner/styles/SuperNodeBanner";
import { useTranslation } from "react-i18next";


const TransactionResponseModal = ({ setTokenTransferStatus, transferedAmount, tokenTransferStatus, selectedCoin, receiver, setIsTransfering, transactionHash }: any) => {
  const { t } = useTranslation()
  return (
    <Box maxWidth={['95%', '100%']}>
      {
        Number(tokenTransferStatus) === 4 && (
          <Box>
            <Box>
              <img src={img5} alt='success' width='32px' />
              <Typography
                style={{ fontSize: '36px' }}
                color={
                  localStorage.getItem('mui-mode') === 'light'
                    ? '#111113'
                    : '#FFFFFF'
                }
              >
                {t('settings.modal.title1')}{' '}
              </Typography>
              <p>
                <span
                  style={{ color: ' #8DA8FF', fontWeight: 700, fontSize: '36px' }}
                >
                  {' '}
                  {t('settings.modal.title2')}{' '}
                </span>
              </p>
            </Box>
            <div>
              <Box
                marginTop={'80px'}
                style={{ cursor: 'pointer' }}
                display='flex'
                flexDirection='column'
                gap='16px'
                justifyContent='space-between'
                padding='16px'
                borderRadius='16px'
                bgcolor={
                  localStorage.getItem('mui-mode') === 'light'
                    ? '#F6F8FF'
                    : '#303032'
                }
              >
                <Box display='flex' justifyContent='space-between'>
                  <Typography
                    style={{
                      fontSize: '14px',
                      lineHeight: '20px',
                      fontWeight: 400,
                      letterSpacing: '0.25px',
                    }}
                    color={
                      localStorage.getItem('mui-mode') === 'light'
                        ? '#111113'
                        : '#F6F1F2'
                    }
                  >
                    {selectedCoin}

                  </Typography>
                  <Typography
                    style={{
                      fontSize: '14px',
                      lineHeight: '20px',
                      fontWeight: 400,
                      letterSpacing: '0.25px',
                    }}
                    color={
                      localStorage.getItem('mui-mode') === 'light'
                        ? '#111113'
                        : '#F6F1F2'
                    }
                  >
                    {transferedAmount}{' '}
                    {selectedCoin}
                  </Typography>
                </Box>
              </Box>
              <hr style={{ marginTop: '10px' }} />
              <Box
                bgcolor={
                  localStorage.getItem('mui-mode') === 'light'
                    ? '#F6F8FF'
                    : '#303032'
                }
                style={{
                  borderRadius: '16px',
                  padding: '8px 16px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItem: 'center',
                  gap: '24px',
                  marginTop: '10px',
                }}
              >
                <Box display={'flex'} flexDirection={'row'} justifyContent={'flex-start'} alignItems={'center'} gap={'16px'} overflow={'hidden'}>
                  <Stack position={'relative'}>
                    <img
                      src={img1}
                      width={70}
                      style={{ borderRadius: '50%' }}
                    />
                    <img
                      src={conetLogo3d}
                      width={60}
                      style={{ position: 'absolute', zIndex: 2 }}
                    />
                  </Stack>
                  <Box width={'100%'} overflow={'hidden'} textOverflow={'ellipsis'}>
                    <Typography marginBottom={'3px'}>Transaction Hash: </Typography>
                    <Link target="_blank" href={`https://scan.conet.network/tx/${transactionHash}`}>{transactionHash}</Link>
                  </Box>
                </Box>


              </Box>
              <div
                style={{
                  marginTop: '32px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <PurchaseButtom
                  style={{
                    width: '100%',
                    textAlign: 'center',
                    maxWidth: '300px',
                    margin: '8px auto',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontWeight: '600',
                    fontSize: '15px',
                    padding: '0.65rem',
                    borderRadius: '0.75rem',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setTokenTransferStatus(0);
                    setIsTransfering(false)
                  }}
                >
                  {t('settings.modal.make')}
                </PurchaseButtom>
                <Stack
                  flexDirection={'row'}
                  justifyContent={'center'}
                  color={
                    localStorage.getItem('mui-mode') === 'light'
                      ? '#5F5E60'
                      : '#FFFFFF'
                  }
                >
                  {/*                   <SvgIcon component={Lock} sx={{ fontSize: '30px' }} />
                  Secure payment */}
                </Stack>
              </div>
            </div>
          </Box>
        )
      }
      {(Number(tokenTransferStatus) >= 0 && Number(tokenTransferStatus) < 4) && (
        <Box textAlign={'center'}>
          <p style={{ color: ' #8DA8FF', fontWeight: 700, fontSize: '24px', marginBottom: '24px' }}> Transaction in progress</p>
          <p style={{ color: '#C8C6C8', fontWeight: 400, fontSize: '14px' }}>Your order completion time may vary, please wait</p>
          <p style={{ color: '#C8C6C8', fontWeight: 400, fontSize: '14px' }}>and we’ll let you know when it’s completed.</p>
          <Stack marginTop={'110px'} height={'190px'} flexDirection={'row'} justifyContent={'center'} position={'relative'}>
            <img className="rotationClock" src={dentro} width={'96px'} height={'96px'} style={{ animation: 'rotation 2s infinite linear', top: 'calc(50% - 48px)', position: "absolute", display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }} />
            <img className="rotation" src={fora} width={'144px'} height={'144px'} style={{ top: 'calc(50% - 72px)', position: "absolute", display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }} />
          </Stack>

          {tokenTransferStatus == 0 && <p style={{ color: '#C8C6C8', fontWeight: 600, textAlign: 'center' }}>{t('settings.modal.status1')}</p>}
          {tokenTransferStatus == 1 && <p style={{ color: '#C8C6C8', fontWeight: 600, textAlign: 'center' }}>{t('settings.modal.status2')}</p>}
          {tokenTransferStatus == 2 && <p style={{ color: '#C8C6C8', fontWeight: 600, textAlign: 'center' }}>{t('settings.modal.status3')}</p>}
          {tokenTransferStatus == 3 && <p style={{ color: '#C8C6C8', fontWeight: 600, textAlign: 'center' }}>{t('settings.modal.status4')}.</p>}


          <p style={{ color: '#C8C6C8', fontWeight: 500, fontSize: '11px', marginTop: '110px', textAlign: 'center', lineHeight: '16px', letterSpacing: '0.5px' }}>{t('settings.modal.wait1')}</p>
          <p style={{ color: '#C8C6C8', fontWeight: 500, fontSize: '11px', textAlign: 'center', lineHeight: '16px', letterSpacing: '0.5px' }}>{t('settings.modal.wait2')}.</p>
          <Stack>
            <PurchaseButtom
              style={{
                width: '100%',
                textAlign: "center",
                maxWidth: '300px',
                margin: '0 auto',
                marginTop: '32px',
                justifyContent: "center",
                alignItems: "center",
                fontWeight: "600",
                fontSize: "15px",
                padding: "0.65rem",
                borderRadius: "0.75rem",
                cursor: "pointer",

              }}
            >
              <Box display={'flex'} justifyContent={'center'} alignItems={'center'} gap={1}>
                <CircularProgress size={24} color="inherit" /> {t('settings.processing')}
              </Box>

            </PurchaseButtom>
          </Stack>
        </Box>
      )}
      {
        Number(tokenTransferStatus) < 0 && (
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }} gap={8}>
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", maxWidth: "320px" }} gap={2}>
              <Box sx={{ maxWidth: '160px', width: '100%', height: "160px", borderRadius: "50%", border: "5px solid white", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <img src={failure} alt='success' width='32px' />
              </Box>
              <Typography style={{ fontSize: '20px', textAlign: "center" }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#FFB4AB"}>{t('dashboard.buyCard.declined')}</Typography>
              {/* <Typography style={{ fontSize: '14px', textAlign: "center" }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#FFFFFF"}>{t('settings.buyCard.sendMessage')}</Typography> */}
            </Box>
            <Box>
              <Typography style={{ fontSize: '14px' }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#3399FF"}>{transactionHash}</Typography>
            </Box>
            <Box>
              <Typography style={{ fontSize: '14px', cursor: 'pointer' }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#3399FF"} onClick={() => { setTokenTransferStatus(0); setIsTransfering(false) }}>{t('dashboard.miningComponent.dismiss')}</Typography>
            </Box>
          </Box>
        )
      }
    </Box>
  )
}

export default TransactionResponseModal