import { ClickAwayListener } from "@mui/base";
import { Box, SvgIcon, Typography, CircularProgress, Skeleton } from "@mui/material";
import { motion } from "framer-motion";
import { ReactComponent as defaultProfilePicture } from '../profileTEST/assets/default_profile.svg';
import { useState } from "react";
import CircleIcon from '@mui/icons-material/Circle';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import cloud from '../miner/assets/cloud.svg';
import { platform } from "../../../../API/platform";
import { useUserProfile } from "../../../../API/useUserProfile";
import { useRefreshStore } from "../../../../API/useRefreshStore";

type ProfileDropdownProps = {
    selectedProfile: any;
    setSelectedProfile: any;
}

export function BridgeProfileDropdown(props: ProfileDropdownProps) {

    const { userProfile, setUserProfile } = useUserProfile();

    const [isProfileDropdownOpen, setProfileDropdownOpen] = useState(false);
    const [isChangingWallet, setChangingWallet] = useState<boolean>(false);
    const [keyProfile, setKeyProfile] = useState<{ index: number; color: string }[]>([]);

    //CONET functions
    const [conetPlatform, setConetPlatform] = useState<any>('')
    const [workerLoading, setWorkerLoading] = useState(0)
    const conet_platform = new platform(setConetPlatform, setWorkerLoading)

    const walletStore = useRefreshStore(state => state.wallets)

    const ChangeProfile = async (wallet: any) => {
        setChangingWallet(true);
        //await conet_platform.stopMining()
        props.setSelectedProfile(wallet)
        //useIsMiningRunning.getState().resetMiningStatus()
        setChangingWallet(false);
    }

    const profileMenuClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setProfileDropdownOpen((prev) => !prev);
    };

    const variants = {
        visible: i => ({
            opacity: 1,
            transition: {
                delay: i * 0.1,
            },
        }),
        hidden: { opacity: 0 },
    };

    const list = {
        visible: { opacity: 1 },
        hidden: { opacity: 0 },
    }


    /*useEffect(() => {
        if (walletStore && walletStore.length > 0) {
            setUserProfile(userProfile)
        }
    }, [walletStore]);*/

    return (
        <Box position="relative" display="flex" flexDirection="column" marginBottom={'20px'}>
            <Box style={{ cursor: "pointer" }} display="flex" alignItems="center" onClick={profileMenuClick} justifyContent="space-between" padding="16px" borderRadius="16px" bgcolor={localStorage.getItem('mui-mode') === 'light' ? "#F6F8FF" : "#191919"}>
                <Box display="flex" gap="8px" alignItems="center">
                    {
                        props.selectedProfile.data && props.selectedProfile?.data?.profileImg ?
                            (<img src={`${props.selectedProfile?.data?.profileImg}`} height='32px' width='32px' style={{ borderRadius: '100%' }} />) :
                            <SvgIcon component={defaultProfilePicture} inheritViewBox sx={{ fontSize: 32 }} />
                    }

                    <Box display="flex" flexDirection="column">
                        <Typography variant="subtitle1" fontSize={'14px'} fontWeight={'500'}>
                            {props.selectedProfile?.data?.nickname || 'Anonymous User'}
                        </Typography>
                        <Typography variant="body2" fontSize={'12px'} color={'#B1B1B2'}>
                            {props.selectedProfile?.keyID?.substring(0, 2) + props.selectedProfile?.keyID?.substring(2, 8).toUpperCase() + '...' + props.selectedProfile?.keyID?.substring(props.selectedProfile?.keyID?.length - 6, props.selectedProfile?.keyID?.length).toUpperCase()}
                        </Typography>
                    </Box>
                </Box>
                {
                    !!props.selectedProfile?.data?.tags && (
                        <Box display="flex" gap="8px" alignItems="center" justifyContent="center">
                            <CircleIcon style={{ color: props.selectedProfile.data.tags }} />
                            <SvgIcon component={KeyboardArrowDownIcon} inheritViewBox sx={{ fontSize: 16 }} />
                        </Box>
                    )
                }
            </Box>
            {isProfileDropdownOpen ? (
                <ClickAwayListener onClickAway={() => setProfileDropdownOpen(false)}>
                    <Box maxWidth="100%" padding={'16px'} overflow="hidden" width={'100%'} position="absolute" top="0" display="flex" gap="8px" zIndex={9999} flexDirection="column" borderRadius="16px" boxShadow="0px 0px 21px 0px rgba(0,0,0,0.1)" bgcolor={localStorage.getItem('mui-mode') === "light" ? "#ffffff" : "#111113"} border={localStorage.getItem('mui-mode') === 'light' ? "1px solid #E4E2E4" : "1px solid #474648"}>
                        {isChangingWallet ? (
                            <Box position="absolute" display="flex" flexDirection="column" gap="24px" alignItems="center" justifyContent="center" bgcolor="red" top={0} left={0} right={0} bottom={0} bgcolor={localStorage.getItem('mui-mode') === "light" ? "#ffffff" : "#111113"}>
                                <Typography variant="subtitle1" fontSize={'18px'} fontWeight={'500'}>
                                    Changing wallet please wait...
                                </Typography>
                                <CircularProgress color='success' disableShrink />
                            </Box>
                        ) : null}

                        <Box style={{ cursor: "pointer" }} display="flex" alignItems="center" onClick={profileMenuClick} justifyContent="space-between" padding="16px" borderRadius="16px" bgcolor={localStorage.getItem('mui-mode') === 'light' ? "#F6F8FF" : "#363E59"}>
                            <Box display="flex" gap="8px" alignItems="center">
                                {
                                    props.selectedProfile.data && props.selectedProfile?.data?.profileImg ?
                                        (<img src={`${props.selectedProfile?.data?.profileImg}`} alt="Imagem Codificada em Base64" height='40px' width='40px' style={{ borderRadius: '100%' }} />) :
                                        <SvgIcon component={defaultProfilePicture} inheritViewBox sx={{ fontSize: 32 }} />
                                }
                                <Box display="flex" flexDirection="column">
                                    <Typography variant="subtitle1" fontSize={'14px'} fontWeight={'500'}>
                                        {props.selectedProfile?.data?.nickname || 'Anonymous User'}
                                    </Typography>
                                    <Typography variant="body2" fontSize={'12px'} color={'#5F5E60'}>
                                        {props.selectedProfile.keyID.substring(0, 2) + props.selectedProfile.keyID.substring(2, 8).toUpperCase() + '...' + props.selectedProfile.keyID.substring(props.selectedProfile.keyID.length - 6, props.selectedProfile.keyID.length).toUpperCase()}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box display="flex" gap="8px" alignItems="center" justifyContent="center">
                                <CircleIcon style={{ color: props.selectedProfile.data && props.selectedProfile.data.tags ? props.selectedProfile.data.tags : '#FFAF00' }} />
                                <SvgIcon component={KeyboardArrowDownIcon} inheritViewBox sx={{ fontSize: 16 }} />
                            </Box>
                        </Box>
                        <Box id='scrollable-content' maxHeight="440px" borderTop={localStorage.getItem('mui-mode') === 'light' ? "1px solid #E4E2E4" : "1px solid #474648"} borderBottom={localStorage.getItem('mui-mode') === 'light' ? "1px solid #E4E2E4" : "1px solid #474648"} display="flex" flexDirection="column" overflow="auto" p={'16px'}>
                            <motion.ul
                                initial="hidden"
                                animate="visible"
                                variants={list}
                                style={{ listStyle: 'none' }}
                            >

                                {!walletStore && walletStore < 1 ? (
                                    <Box display="flex" flexDirection="column">
                                        <Skeleton width={'100%'} height={'230px'} />
                                        <Skeleton width={'100%'} height={'230px'} />
                                        <Skeleton width={'100%'} height={'230px'} />
                                    </Box>) : walletStore.map((w: any, key: number) => (
                                        <motion.li custom={key} animate={'visible'} variants={variants} initial='hidden'>
                                            <Box key={key} flexShrink={0} style={{ cursor: "pointer" }} height="68px" display="flex" alignItems="center" onClick={() => { ChangeProfile(w) }} justifyContent="space-between">


                                                <Box display="flex" gap="8px" alignItems="center" >
                                                    {
                                                        w.data && w?.data?.profileImg ?
                                                            (<img src={`${w.data.profileImg}`} alt="Imagem Codificada em Base64" height='40px' width='40px' style={{ borderRadius: '100%' }} />) :
                                                            <SvgIcon component={defaultProfilePicture} inheritViewBox sx={{ fontSize: 32 }} />
                                                    }
                                                    <Box display="flex" flexDirection="column">
                                                        <Typography variant="subtitle1" fontSize={'14px'} fontWeight={'500'}>
                                                            {w.data && w.data.nickname ? w.data.nickname : `Account ${key}`}
                                                        </Typography>
                                                        <Typography variant="body2" fontSize={'12px'} color={'#5F5E60'}>
                                                            {w.keyID.substring(0, 2) + w.keyID.substring(2, 8).toUpperCase() + '...' + w.keyID.substring(w.keyID.length - 6, w.keyID.length).toUpperCase()}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                                <Box display='flex' justifyContent='center' alignItems='center' gap='16px'>
                                                    {!!w?.isNode === true && (
                                                        <img src={cloud} alt='cloud-icon' height='16px' width='16px' />
                                                    )}
                                                    {
                                                        !!w?.data?.tags && (
                                                            <Box display="flex" gap="8px" alignItems="center" justifyContent="center">
                                                                <CircleIcon style={{ color: w.data?.tags }} />
                                                            </Box>
                                                        )
                                                    }
                                                </Box>


                                            </Box>
                                        </motion.li>
                                    ))}

                            </motion.ul>
                        </Box>
                    </Box>

                </ClickAwayListener>
            ) : null}
        </Box>
    )
}