import React, { useEffect, useState } from "react";
import ProviderProps from "../ProviderProps";
import GlobalStyle from "../../UI/Global/Styles";
import {
  DefaultTheme,
  ThemeProvider as StyledComponentsThemeProvider,
} from "styled-components";
import useAppState from "../../../store/appState/useAppState";
import usePreferredTheme from "./usePreferredTheme";
import lightTheme from "../../../theme/light";
import darkTheme from "../../../theme/dark";

type ActiveTheme = "Light" | "Dark";

const ThemeProvider = ({ children }: ProviderProps) => {
  const themeToConfig: Record<ActiveTheme, DefaultTheme> = {
    Light: lightTheme,
    Dark: darkTheme,
  };
  const [activeTheme, setActiveTheme] = useState<ActiveTheme>("Light");
  const [firstLoad, setFirstLoad] = useState(true);

  const appState = useAppState();
  const preferredTheme = usePreferredTheme();

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false);
      return;
    }

    if (appState.theme === "Auto" && preferredTheme !== activeTheme) {
      setActiveTheme(preferredTheme);
    } else if (appState.theme !== "Auto" && appState.theme !== activeTheme) {
      setActiveTheme(appState.theme);
    }
  }, [appState.theme]);

  return (
    <StyledComponentsThemeProvider theme={themeToConfig[activeTheme]}>
      <GlobalStyle />
      <>{children}</>
    </StyledComponentsThemeProvider>
  );
};

export default ThemeProvider;
