export const leaderboardEn = {
    'title': 'Leaderboard',
    'freeMiners': 'Free Miners',
    'invite': 'Invite your friends to rank up',
    'epoch': 'Epoch',
    'total': 'Total Online Miners',
    'rate': 'Rate',
    'copyButton': 'Copy my referral link',
    'header': {
        'rank': 'Rank',
        'user': 'User',
        'online': 'Online Referrals',
        'cntp': 'CNTP/second'
    },
}

export const leaderboardZh = {
    'title': '排行榜',
    'freeMiners': '自由矿工',
    'invite': '邀请好友提升等级',
    'epoch': '纪元',
    'total': '在线矿工总数',
    'rate': '费率',
    'copyButton': '复制我的推荐链接',
    'header': {
        'rank': '等级',
        'user': '用户',
        'online': '在线推荐',
        'cntp': 'CNTP/秒'
    },
}