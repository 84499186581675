import {
    CssVarsProvider,
    useColorScheme,
    extendTheme,
    styled,
    useTheme,
} from "@mui/material-next/styles";

import {
    HTMLAttributes,
    useState,
    useEffect,
    SyntheticEvent,
    ReactNode,
    useMemo,
    useRef,
} from "react";

// images import

import xIcon from "../../../../assets/icons/footerIcons/x.svg"
import githubIcon from "../../../../assets/icons/footerIcons/github.svg"
import mediumIcon from "../../../../assets/icons/footerIcons/medium.svg"
import telegramIcon from "../../../../assets/icons/footerIcons/telegram.svg"
import youtubeIcon from "../../../../assets/icons/footerIcons/youtube.svg"
import zealyIcon from "../../../../assets/icons/footerIcons/zealy.png"
import discordIcon from "../../../../assets/icons/footerIcons/discord.svg"

import githubBlack from './assets/github.png'
import communicationBlack from './assets/communication.png'
import mediumBlack from './assets/medium.png'
import twitterBlack from './assets/twitter.png'
import discordBlack from './assets/discord.png'
import youtubeBlack from './assets/youtube.png'

// import a from '../../../../assets/pdf/'

import download from './assets/download.svg'

import {
    Box,
    Stack,
    Typography
} from "@mui/material";
import { border, borderColor, borderRadius, padding, width } from "@mui/system";
import { useTranslation } from "react-i18next";

const StackContainer = styled(Stack)(() => ({
    width: "100%",
    padding: "40px 16px",
    alignItems: "center",
    paddingBottom: '100px'
}));

const ImageListing = styled(Stack)(() => ({
    display:"flex",
    flexWrap: 'wrap',
    paddingTop: "16px",
    width: "100%",
    flexDirection: ["column","row","row"],
    gap: "16px",
    justifyContent: "center"
}));

const FooterPlatform = () => {

    const { t } = useTranslation()

    return (
        <StackContainer>
            <Stack style={{ display: 'flex', gap: '16px', justifyContent: 'center', alignItems: 'center', marginBottom: '40px', width:'100%' }} sx={{ flexDirection: ['column','column','row']}}>
                <a download="FAQ_EN.pdf" href="/FAQ_EN.pdf" style={{textDecoration:"none", width:'100%', maxWidth:'320px'}}>
                    <Box style={{cursor:"pointer", width:'100%' ,maxWidth: '320px', border: 'solid 1px', borderColor: '#474648', borderRadius: '8px', padding: '16px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography style={{color:localStorage.getItem('mui-mode') === 'light' ? 'black' : "white"}}>{t('footer.faq')}</Typography>
                        <img src={download} width={32} alt='download-icon' />
                    </Box>
                </a>
                <a download="UserManual_EN.pdf" href="/UserManual_EN.pdf" style={{textDecoration:"none", width:'100%', maxWidth:'320px'}}>
                    <Box style={{cursor:"pointer", width:'100%' ,maxWidth: '320px', border: 'solid 1px', borderColor: '#474648', borderRadius: '8px', padding: '16px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography style={{color:localStorage.getItem('mui-mode') === 'light' ? 'black' : "white"}} >{t('footer.manual')}</Typography>
                        <img src={download} width={32} alt='download-icon' />
                    </Box>
                </a>
            </Stack>
            <hr style={{ width: '100%', marginBottom: '40px' }} />
            <Typography style={{ textAlign: 'center' }}>{t('footer.community')}</Typography>
            <ImageListing>
                <a href={"https://twitter.com/CoNET_Network"} target="_blank">
                    <img src={localStorage.getItem('mui-mode') === 'light' ? twitterBlack : xIcon} width={32} alt="xIcon" />
                </a>
                <a href={"https://discord.gg/JrpMBFkewd"} target="_blank">
                    <img src={localStorage.getItem('mui-mode') === 'light' ? discordBlack : discordIcon} width={32} alt="githubIcon" />
                </a>
                {/* <a href={"https://www.youtube.com/channel/UCNEBenkWrmoRxxfKxZStLrw"} target="_blank">
                    <img src={localStorage.getItem('mui-mode') === 'light' ? youtubeBlack : youtubeIcon} width={32} alt="youtubeIcon" />
                </a> */}
                <a href={"https://t.me/+NInogjtc8Ak3OWFk"} target="_blank">
                    <img src={localStorage.getItem('mui-mode') === 'light' ? communicationBlack : telegramIcon} width={32} alt="telegramIcon" />
                </a>
                <a href={"https://conet.medium.com"} target="_blank">
                    <img src={localStorage.getItem('mui-mode') === 'light' ? mediumBlack : mediumIcon} width={32} alt="mediumIcon" />
                </a>
                <a href={"https://github.com/CoNET-project"} target="_blank">
                    <img src={localStorage.getItem('mui-mode') === 'light' ? githubBlack : githubIcon} width={32} alt="zealyIcon" />
                </a>
                <a href={"https://zealy.io/cw/conet/leaderboard/e0a50398-0d31-4692-a1bb-2dd7a8c58a56"} target="_blank">
                    <img src={zealyIcon} width={32} alt="zealyIcon" />
                </a>
            </ImageListing>

        </StackContainer>
    )
}

export default FooterPlatform