import { Stack, Typography } from "@mui/material";

interface IDiscoverButtomProps {
    selectedImg: string;
    whiteImg: string;
    blackImg: string;
    title: string;
    setSelectedDiscover: React.Dispatch<React.SetStateAction<string>>;
    selectedDiscover: string;
    tag: string;
    filterApps: any;
}

export default function DiscoverButtom({ selectedImg, whiteImg, blackImg, title, tag, selectedDiscover, setSelectedDiscover, filterApps }: IDiscoverButtomProps) {
    return (
        <Stack border={`1px solid ${selectedDiscover === tag ? '#8DA8FF' : localStorage.getItem('mui-mode') === 'light' ? "#E4E2E4" : "#474648"}`} bgcolor={localStorage.getItem('mui-mode') === 'light' ? "#FFF" : "#262527"} borderRadius={'8px'} px={2} py={1} style={{ maxWidth: 'fit-content', cursor: 'pointer' }} display={'flex'} flexDirection={'row'} alignItems={'center'} gap={1} onClick={() => { setSelectedDiscover(tag); filterApps(tag) }}>
            <img src={selectedDiscover === tag ? selectedImg : localStorage.getItem('mui-mode') === 'light' ? blackImg : whiteImg} width={'20px'} />
            <Typography fontSize={'14px'} color={selectedDiscover === tag ? '#8DA8FF' : localStorage.getItem('mui-mode') === 'light' ? "#000" : "#fff"}>
                {title}
            </Typography>
        </Stack>
    )
}