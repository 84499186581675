export const claimEn = {
  title1: "Claim",
  title2: "Assets",
  bannerTitle: "$CONET Live Soon!",
  bannerCaption:
    "The Token Generation Event (TGE) for $CONET will begin soon — join the revolution now!",
  yourAssets: "Your assets",
  guardianNft: "Guardian NFT",
  conetianNft: "CoNETian NFT",
  balance: "Balance",
  comingSoon: "Claiming Soon",
  claimAllocation: "Claim Allocation",
  guardian: "Guardian",
  conetian: "CoNETian",
  miner: "Free Miners, Guardians, or CoNETians",
  airdropAllocation: "Airdrop Allocation",
  forEachPlan: "for each plan",
  receive: "Receive",
  card1: {
    description: {
      first: "Unlock after 5 years followed by a 12-month vesting.",
    },
    descriptionDetail: "Available prior to TGE",
  },
  card2: {
    description: {
      first: "25% unlocked immediately.",
      second: "75% will vest linearly over 6 months.",
    },
    descriptionDetail: "Available prior to TGE",
  },
  card3: {
    description: {
      first: "30% of the airdropped tokens will be unlocked immediately.",
      second: "70% will vest linearly over 6 months.",
    },
    descriptionDetail: "Available for eligible participants",
  },
};

export const claimZh = {
  title1: "领取",
  title2: "资产",
  bannerTitle: "$CONET 即将上线！",
  bannerCaption: "$CONET的代币生成事件（TGE）即将开始 – 立 即加入变革吧！",
  yourAssets: "你的资产",
  guardianNft: "守护者 NFT",
  conetianNft: "CoNETian NFT",
  balance: "余额",
  comingSoon: "敬请期待",
  claimAllocation: "领取资产",
  guardian: "守护者",
  conetian: "CoNETian",
  miner: "免费矿工、守护者或CoNETian",
  airdropAllocation: "空投分配",
  forEachPlan: "每个计划",
  receive: "接收",
  card1: {
    description: {
      first: "5年锁定期后，将在12个月内线性解锁",
    },
    descriptionDetail: "TGE 之前可用",
  },
  card2: {
    description: {
      first: "25% 立即解锁",
      second: "75%将在6个月内线性解锁",
    },
    descriptionDetail: "TGE 之前可用",
  },
  card3: {
    description: {
      first: "30% 立即解锁",
      second: "70%将在6个月内线性解锁",
    },
    descriptionDetail: "适用于符合条件的参与者",
  },
};
