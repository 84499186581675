export const footerEn = {
    'faq': 'Download our FAQ',
    'manual': 'Download the User Manual',
    'community': 'Community'
}

export const footerZh = {
    'faq': '下载常见问题答复',
    'manual': '下载用户使用手册',
    'community': '社区'
}