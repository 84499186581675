export const sideBarEn = {
  dashboard: "Dashboard",
  leaderboard: "Leaderboard",
  apps: "Apps",
  swap: "Swap",
  claim: "Claim",
  language: "Language",
  new: "New",
};

export const sideBarZh = {
  dashboard: "仪表板",
  leaderboard: "排行榜",
  apps: "应用商店",
  swap: "交换",
  claim: "领取",
  language: "语言",
  new: "新",
};
