import { Button, FormControl, Input, MenuItem, Select, Stack, Typography, useTheme } from '@mui/material';
import { useRefreshStore } from '../../../../API/useRefreshStore';
import { useState } from 'react';

import { ReactComponent as DefaultProfilePicture } from "../profileTEST/assets/default_profile.svg";
import { ReactComponent as IconSwap } from "../../../../assets/images/swap.svg";
import { BiChevronDown } from 'react-icons/bi';
import { SwapHoriz } from '@mui/icons-material';

type Network = "arbitrum" | "conet";
type Asset = "cntp" | "conet";

export default function Swap() {
  const walletState = useRefreshStore((state) => state.wallets);
  const theme = useTheme();

  const [choosenWallet, setChoosenWallet] = useState(walletState[0]);
  const [choosenNetwork, setChoosenNetwork] = useState<Network>("arbitrum");
  const [fromAsset, setFromAsset] = useState<Asset | "">("");
  const [toAsset, setToAsset] = useState<Asset | "">("");
  const [faQtd, setFaQtd] = useState(0);
  const [taQtd, setTaQtd] = useState(0);

  const [advancedOptionsOpen, setAdvancedOptionsOpen] = useState<boolean>(false);

  function handleChangeWallet(value: string) {
    const currentWallet = walletState.find((wallet) => wallet.keyID === value);

    setChoosenWallet(currentWallet)
  }

  function maximizeFromAsset() {

  }

  function getQuotes() {

  }

  function openTermsAndService() {

  }

  function invertAssets() {
    const firstAsset = fromAsset;

    setFromAsset(toAsset);
    setToAsset(firstAsset);
  }

  // console.log("wallet state: ", walletState)

  return (
    <Stack minHeight={'90vh'} padding={'16px'} height={'90vh'} minWidth={['0', '450px']} width={'100%'} alignItems={'center'} justifyContent={'center'}>
    <Typography fontWeight={'800'} fontSize={'24px'} width={'100%'} textAlign={'left'} maxWidth={'450px'}>Swap</Typography>
    <Stack
      marginTop="12px"
      padding="16px"
      width="100%"
      maxWidth="450px"
      borderRadius="16px"
      bgcolor={theme.palette.mode === "light" ? "#F3F0F2" : "#1B1B1B"}
      color={theme.palette.mode === "light" ? "black" : "white"}
    >
      <FormControl
        fullWidth
        size="small"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          alignItems: "center",
        }}
      >
        {/* WALLET SELECT */}
        <Select value={choosenWallet.keyID} label="Wallet" onChange={(e) => handleChangeWallet(e.target.value)} sx={{ borderRadius: "8px" }} fullWidth style={{display:'flex'}}>
          {
            walletState.map((wallet) => (
              <MenuItem value={wallet.keyID} sx={{ display: "flex", alignItems: "center", gap: "2rem" }}>
                <DefaultProfilePicture width={30} height={30} />
                <Typography>{wallet.keyID?.substring(0, 2) + wallet.keyID?.substring(2, 8).toUpperCase() + '...' + wallet.keyID?.substring(wallet.keyID?.length - 6, wallet.keyID?.length).toUpperCase()}</Typography>
              </MenuItem>
            ))
          }
        </Select>
        {/* NETWORK SELECT */}
        <Select value={choosenNetwork} label="Wallet" onChange={(e) => setChoosenNetwork(e.target.value as Network)} sx={{ borderRadius: "8px", display:"flex" }} fullWidth>
          <MenuItem value="arbitrum" sx={{ display: "flex", alignItems: "center", gap: "2rem" }}>
            <DefaultProfilePicture width={30} height={30} />
            <Typography>Arbitrum</Typography>
          </MenuItem>
          <MenuItem value="conet" sx={{ display: "flex", alignItems: "center", gap: "2rem" }}>
            <DefaultProfilePicture width={30} height={30} />
            <Typography>CoNET</Typography>
          </MenuItem>
        </Select>
        <Stack width="100%">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography fontWeight="700">From</Typography>
            <Button onClick={maximizeFromAsset} sx={{ color: "#8DA8FF", fontWeight: 700 }}>MAX</Button>
          </Stack>
          <Stack direction="row" gap="20px" alignItems="center" fontSize={'14px'}>
            <Select value={fromAsset} onChange={(e) => setFromAsset(e.target.value as Asset)} sx={{ flex: 2, borderRadius: "8px" }}>
              <MenuItem value="conet">CONET</MenuItem>
              <MenuItem value="cntp">CNTP</MenuItem>
            </Select>
            <Input type="number" value={faQtd} sx={{ flex: 4 }} />
          </Stack>
        </Stack>
        <Button sx={{ alignSelf: "flex-end", transform: "rotate(90deg)"  }} onClick={invertAssets}>
          <SwapHoriz width={24} height={24} style={{ opacity: .8 }} htmlColor={theme.palette.mode === "light" ? "black" : "white"} />
        </Button>
        <Stack width="100%">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography fontWeight="700">To</Typography>
            <Button onClick={maximizeFromAsset} sx={{ color: "#8DA8FF", fontWeight: 700 }}>MAX</Button>
          </Stack>
          <Stack direction="row" gap="20px" alignItems="center">
            <Select value={toAsset} onChange={(e) => setToAsset(e.target.value as Asset)} sx={{ flex: 2, borderRadius: "8px" }}>
              <MenuItem value="conet">CONET</MenuItem>
              <MenuItem value="cntp">CNTP</MenuItem>
            </Select>
            <Input type="number" value={faQtd} sx={{ flex: 4 }} />
          </Stack>
        </Stack>
        <Stack margin="8px 0" direction="column" height={advancedOptionsOpen ? "120px" : "26px"} sx={{ transition: ".2s ease" }} overflow="hidden">
          <Stack
            direction="row" gap="8px" alignItems="center"
            onClick={() => setAdvancedOptionsOpen((prev) => !prev)}
            marginBottom="12px" style={{ cursor: "pointer" }}
          >
            <Typography fontWeight="700">Advanced Options</Typography>
            <BiChevronDown style={{ transition: ".2s ease", transform: advancedOptionsOpen ? "rotate(180deg)" : "rotate(0)" }} />
          </Stack>
          <Stack height="80px">
            <Typography>AO Content.</Typography>
          </Stack>
        </Stack>
        <Stack width="100%">
          <Button fullWidth variant="contained" onClick={getQuotes} sx={{ borderRadius: "8px", background: "linear-gradient(92.05deg, rgb(215, 117, 255) 33%, rgb(141, 168, 255) 66%)", fontWeight: 700 }}>Get Quotes</Button>
          <Button fullWidth variant="text" onClick={openTermsAndService} sx={{ borderRadius: "8px", color: "#8DA8FF", fontWeight: 700, fontSize: '10px', marginTop:'8px' }}>Terms of Service</Button>
        </Stack>
      </FormControl>
    </Stack>
    </Stack>
  )
}