import PouchDB from 'pouchdb'
import PouchDBFind from 'pouchdb-find';

interface Record {
  _id: string;
  userId: string;
  currentDate: string;
}

PouchDB.plugin(PouchDBFind)

const db = new PouchDB<Record>('_pouch_CONET_triggered_updates');

db.createIndex({
  index: {
    fields: ['userId', 'currentDate']
  }
})

async function createRecord(userId: string, currentDate: string): Promise<void> {
  const newRecord: Record = {
    _id: `${userId}_${currentDate}`,
    userId,
    currentDate
  };

  await db.put(newRecord);
}

async function latestUpdatesWereTriggered(userId: string, currentDate: string): Promise<boolean> {
  try {
    const result = await db.find({
      selector: { userId, currentDate },
      limit: 1
    });

    if (result.docs.length > 0) {
      return true;
    } else {
      await createRecord(userId, currentDate);
      return false;
    }
  } catch (error) {
    console.error('Error while fetching or trying to create a new registry:', error);
    throw error;
  }
}

export { latestUpdatesWereTriggered }