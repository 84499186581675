import styled from "styled-components";

export const CommunityLivenessWrapper = styled.div`
  display: flex;
  /* background: ${(props) => props.theme.ui.colors.background.elevationOne}; */
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const CommunityLivenessContent = styled.div`
  display: flex;
  flex-direction: column;
  /* width: 100%; */
  /* padding: 4rem 3rem; */
  align-items: flex-start;
  justify-content: center;
  width:100%;
  max-width:300px;

  @media (max-width: 600px) {
    max-width: fit-content;
  }


`;

export const CardLinkPrices = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 291px;
  height: 255px;
  background-color: #363E59;
  border-radius: 1rem;
  padding: 24px;
  position: relative;
  color: ${(props) => props.theme.ui.colors.primary};
  background-image: url('../../../../../../assets/Logo_White_TransparentBG.png');
  background-size: cover;
  background-position: center;
  width:100%;

  @media (max-width: 600px) {
    max-width: fit-content;
  }

  .svgicon {
    position: absolute !important;
    transform: translate(0, -17%);
    opacity: 0.5;
  }
`

export const StartMiningCard = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 255px;
  flex-direction: column;
  min-height: 9.875rem; */
  border-radius: 1rem;
  color: ${(props) => props.theme.ui.colors.text.inverted};
  padding: 2rem;
  cursor:pointer;
  border-radius: 16px;

  &:hover{
    .logoMining {
      transform: rotate(45deg) scale(1.1);
    }
  }

  .logoMining {
    transition: transform 0.3s ease;

    padding-right:16px;
    
    width: 120px;
    height: 120px;

    path{
      transition: fill 0.3s ease;
      fill: white;
    }

    @media (max-width: 768px) {
      width: 90px;  
      height: 90px;
    }
  }

  @keyframes rotateInfinite {
    from {
      transform: rotate(45deg);
    }
    to {
      transform: rotate(405deg);
    }
  }

  @keyframes fancy-spin {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(75deg);
    }
    50% {
      transform: rotate(0deg);
    }
    75% {
      transform: rotate(-75deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }


  .logoMiningLoop {

    animation: rotateInfinite 1.5s infinite linear;

    padding-right:16px;
    
    width: 120px;
    height: 120px;

    path{
      transition: fill 0.8s ease;
      fill: #8d4de3;
    }

    @media (max-width: 768px) {
      width: 90px;  
      height: 90px;
    }
  }

  .reconnectingLoop {

    animation: fancy-spin 3s infinite linear;
    animation-iteration-count: infinite;
    padding-right:16px;
    
    width: 120px;
    height: 120px;

    path{
      transition: fill 0.8s ease;
      fill: #8d4de3;
    }

    @media (max-width: 768px) {
      width: 90px;  
      height: 90px;
    }
  }


  @media (max-width: 945px) {
    
    flex-direction: column;
    
    img{
      width: 240px;
      position:absolute;
      bottom: 16p;
      right: 16px;
    }  
  }

  @media (max-width: 768px) {
    img{
      width: 240px;
      position:absolute;
      bottom: 16p;
      right: 16px;
    }  
  }
  

`;

export const StartMiningButton = styled.button<{ isRunning: boolean }>`
  height: fit-content;
  border: none;
  color: ${(props) => props.theme.ui.colors.text.inverted};
  opacity: ${(props) => (props.isRunning ? 0.8 : 1)};

  background: transparent;
  cursor: pointer;
  z-index: 2;
`;