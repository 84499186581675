export const dAppsEn = {
  titleWhite: "CoNET",
  titleBlue: "Apps",
  silentPassTitle: "Silent Pass",
  silentPassSubTitle:
    "Silent Pass is an application based on the decentralized, zero-trust, privacy-first CONET Layer Minus network.",
  silentPassButton: "Click to download",
  discover: "Discover",
  bringTitle: "Bring your dApp to CoNET",
  bringSubTitle:
    "You can create dApps on CoNET without the need to learn a new toolkit. Your code, tools, and infrastructure are ready to use immediately.",
  popular: "Popular Now",
  discoverButton: {
    all: "All",
    newest: "Newest",
    design: "Design",
    gaming: "Gaming",
    pet: "PET",
    cs: "Coming Soon",
    mining: "Mining",
    communication: "Communication",
    entertainment: "Entertainment",
    media: "News Media",
    office: "Office",
    tool: "Tool",
  },
  dApps: {
    conetianTelegram: {
      title: "CoNETian\n\n(Telegram version)",
      description:
        "Play and earn rewards by completing tasks directly on Telegram.",
    },
    conetianWeb: {
      title: "CoNETian\n\n(Web version)",
      description: "Earn rewards by completing tasks on our browser mini game.",
    },
    proxyExtension: {
      title: "Conet Proxy Extension",
      description: "Run Silent Pass easily with auto proxy settings.",
    },
    miningExtension: {
      title: "Conet Wallet-Mining Extension",
      description:
        "Start mining directly from your browser with a single click.",
    },
    fx168: {
      title: "FX168",
      description:
        "Stay updated with FX168 news and earn through CoNET features.",
    },
    silentProxy: {
      title: "Silent Pass Proxy",
      description: "Secure your connection with CoNET’s decentralized proxy.",
    },
    silentVpn: {
      title: "Silent Pass VPN",
      description: "Browse privately and securely with CoNET’s VPN.",
    },
    conetianMessenger: {
      title: "CoNETian Messenger",
      description: "Chat securely with wallet-based private messaging.",
    },
    conetianStorage: {
      title: "CoNETian Storage",
      description: "Store and share files securely with decentralized storage.",
    },

    getApp: "Get App",
    comingSoon: "Coming Soon",
  },
};

export const dAppsZh = {
  titleWhite: "CoNET",
  titleBlue: "应用程序",
  silentPassTitle: "Silent Pass",
  silentPassSubTitle:
    "Silent Pass 是一款基于去中心化、零信任、隐私优先的 CONET Minus 层网络的应用程序",
  silentPassButton: "点击下载",
  discover: "发现",
  bringTitle: "将您的 dApp 带到 CoNET",
  bringSubTitle:
    "您无需学习新的工具包，即可在 CoNET 上创建 dApps。您的代码、工具和基础设施可立即投入使用。",
  popular: "现在流行",
  discoverButton: {
    all: "全部",
    newest: "最新",
    design: "设计",
    gaming: "游戏",
    pet: "宠物",
    cs: "即将推出",
    mining: "挖矿",
    communication: "交流",
    entertainment: "娱乐",
    media: "媒体",
    office: "办公室",
    tool: "工具",
  },

  dApps: {
    conetianTelegram: {
      title: "CoNETian\n\n(Telegram 版本)",
      description: "通过在Telegram上完 成游戏任务来 赚取奖励",
    },
    conetianWeb: {
      title: "CoNETian\n\n(网络版)",
      description: "通过在网页浏 览器上完成游 戏任务来赚取 奖励",
    },
    proxyExtension: {
      title: "CoNET 代理服 务器扩展应用",
      description: "通过自动设置 代理服务器来 运行Silent Pass",
    },
    miningExtension: {
      title: "CoNET 钱包挖 矿扩展应用",
      description: "只需简单点击 即可开启挖矿",
    },
    fx168: {
      title: "FX168",
      description: "随时了解 FX168 新闻，并通过 CoNET 功能赚取奖励",
    },
    silentProxy: {
      title: "Silent Pass 代理",
      description: "通过 CoNET 的去中心化代理保护您的连接",
    },
    silentVpn: {
      title: "Silent Pass 虚拟专用网",
      description: "通过 CoNET 的 VPN 私密且安全地浏览",
    },
    conetianMessenger: {
      title: "CoNETian 信使",
      description: "通过基于钱包的私密消息传递安全地聊天",
    },
    conetianStorage: {
      title: "CoNETian 存储",
      description: "通过去中心化存储安全地存储和分享文件",
    },

    getApp: "获取应用",
    comingSoon: "敬请期待",
  },
};
