import { useEffect, useState } from "react";
import { platform, listeningAssetsHook } from "./platform";
import { useTypedSelector } from "../store/store";
import { useUserProfile } from "./useUserProfile";
import { useRefreshStore } from "./useRefreshStore";

export function useFetchData() {
  const [state, setState] = useState({
    wallet: [],
    referees: [],
    leaderboard: {},
    oracleAssets: {},
    monitoredWallets: [],
  });
  const [conetPlatform, setConetPlatform] = useState<any>("");
  const [workerLoading, setWorkerLoading] = useState(0);
  const [statusChange, setStatusChange] = useState<any>("");
  const authKey = useTypedSelector((state) => state.appState.pass);
  const profile = useTypedSelector((state) => state.appState.activeProfile);
  const { userProfile, setUserProfile } = useUserProfile();

  useEffect(() => {
    const conet_platform = new platform(setConetPlatform, setWorkerLoading);
    let cleanup: any;

    cleanup = listeningAssetsHook(setStatusChange);

    return () => {
      if (cleanup) cleanup();
    };
  }, []);

  useEffect(() => {
    if (statusChange !== "") {
      try {
        setState((prev) => ({
          wallet: statusChange[0] || prev?.wallet,
          referees: statusChange[1] || prev?.referees,
          leaderboard: statusChange[2] || prev?.leaderboard,
          oracleAssets: statusChange[3] || prev?.oracleAssets,
          monitoredWallets: statusChange[4] || prev?.monitoredWallets,
        }));

        statusChange[1] &&
          useRefreshStore.getState().setReferees(statusChange[1]);
        statusChange[2] &&
          useRefreshStore.getState().setLeaderboards(statusChange[2]);
        statusChange[3] &&
          useRefreshStore.getState().setOracleAssets(statusChange[3]);
        statusChange[4] &&
          useRefreshStore.getState().setMonitoredWallets(statusChange[4]);

        if (statusChange[0]) {
          statusChange[0] &&
            useRefreshStore.getState().setWallets(statusChange[0]);

          const userToUpdate = statusChange[0]?.find((w: any) => {
            return w?.keyID?.trim() === userProfile?.keyID?.trim();
          });

          userToUpdate && setUserProfile(userToUpdate);
        }
      } catch (err) {
        console.log("Error in useFetchData useEffect", err);
      }
    }
  }, [statusChange]);

  return { ...state };
}
