import { useColorScheme } from '@mui/material-next/styles';
import { useState } from 'react';
import { platform } from '../../../../../API/platform';
import { useTypedSelector } from '../../../../../store/store';
import { ClickAwayListener } from '@mui/base';
import { SvgIcon, Stack, Typography, TextField, Box, Button, CircularProgress } from '@mui/material';
import { ReactComponent as ConetAvatar } from "../../../../../assets/images/con-avatar.svg";
import EditIcon from '@mui/icons-material/Edit';
import { FormattedMessage } from 'react-intl';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import CheckIcon from '@mui/icons-material/Check';
import { toast } from 'react-toastify';
import { ReactComponent as CloseIcon } from '../../../../../assets/icons/Close.svg'
import { ReactComponent as CloseIconBlack } from '../../../../../assets/icons/Close_black.svg'
import { useTranslation } from 'react-i18next';


interface INewWalletModal {
    refresh: boolean;
    setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const NewWalletModal = (props: INewWalletModal) => {
    const { mode, setMode } = useColorScheme();
    const { t } = useTranslation()
    const [name, setName] = useState<string>('')
    const [profileImage, setProfileImage] = useState<string>('')
    const [isTagOpen, setTagOpen] = useState(false);
    const [selectedTag, setSelectedTag] = useState<string>('')
    const [isProfileOpen, setIsProfileOpen] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [conetPlatform, setConetPlatform] = useState<any>('')
    const [workerLoading, setWorkerLoading] = useState(0)
    const conet_platform = new platform(setConetPlatform, setWorkerLoading)

    const authKey = useTypedSelector(state => state.appState.pass)

    function convertImageToBase64(fileInput: HTMLInputElement) {
        const file = fileInput.files?.[0];

        if (file) {
            const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
            if (!allowedTypes.includes(file.type)) {
                alert("Please select a valid image file (PNG, JPG, JPEG).");
                return;
            }

            const reader = new FileReader();
            reader.onload = function () {
                const base64String = reader.result as string;
                setProfileImage(base64String)
            };
            reader.readAsDataURL(file);
        }
    }

    const handlePictureUpload = () => {
        const fileInput = document.createElement("input");
        fileInput.type = "file";
        fileInput.accept = "image/png, image/jpeg, image/jpg";
        fileInput.addEventListener("change", () => convertImageToBase64(fileInput));
        fileInput.click();
    }


    const tagColors = [
        "#FF5E5A", "#FFAF00", "#FFDE00", "#00E361", "#009CFF", "#D873FB", "#ACACB0"
    ]

    const handleSubmit = async () => {
        setIsLoading(true)
        if (name.length > 0) {
            const newProfile = {
                nickname: name,
                tag: selectedTag,
                profileImg: profileImage
            }
            const res = await conet_platform.addProfile(authKey, newProfile)
            setIsLoading(false)
            if (res.length > 0) {
                setName('')
                setProfileImage('')
                props.setRefresh(!props.refresh)
                toast.success('New profile created', { position: 'bottom-center', autoClose: 1500 })
                props.setShowModal(false)
            } else {
                setIsLoading(false)
                toast.error('It was not possible to create a new wallet, try again later', { position: 'bottom-center', autoClose: 1500 })
            }
        }
    }

    return (
        <Box borderRadius="16px" display="flex" flexDirection="column" alignItems={'center'} gap="24px" border={localStorage.getItem('mui-mode') === 'light' ? "1px solid #E4E2E4" : "1px solid #474648"} bgcolor={localStorage.getItem('mui-mode') === 'light' ? 'white' : '#111113'} padding="24px">

            <Box display='flex' justifyContent='space-between' width={'100%'}>
                <Typography style={{ textTransform: 'uppercase', fontWeight: '700', fontSize: '16px', width: 'fit-content' }}>{t('settings.createNew')}</Typography>
                <SvgIcon component={localStorage.getItem('mui-mode') === 'light' ? CloseIconBlack : CloseIcon} inheritViewBox onClick={() => props.setShowModal(false)} />
            </Box>

            <Box display="flex" position="relative" style={{ cursor: 'pointer' }}>
                {profileImage ? (
                    <img src={profileImage} alt="Profile" style={{ width: '72px', height: '72px', borderRadius: "100%" }} />
                ) : (
                    <>
                        <SvgIcon component={ConetAvatar} inheritViewBox sx={{ fontSize: 72 }} />
                    </>
                )}
            </Box>
            <Stack justifyContent="space-evenly" direction="row" gap="12px">
                <Button onClick={handlePictureUpload} style={{ backgroundColor: localStorage.getItem('mui-mode') === 'light' ? "#577DFF" : "#8DA8FF", borderRadius: "16px", height: "52px", padding: "16px 32px 16px 32px" }}>
                    <Typography fontWeight="bold" color={localStorage.getItem('mui-mode') === 'light' ? "white" : "#262527"} fontSize="16px">
                    {t('settings.upload')}
                    </Typography>
                </Button>
                <Button style={{ borderRadius: "16px", height: "52px", padding: "16px, 32px, 16px, 32px" }} onClick={() => setProfileImage('')}>
                    <Typography fontWeight="bold" color={localStorage.getItem('mui-mode') === 'light' ? "red" : "#FFB4AB"} fontSize="16px">
                    {t('settings.cancel')}
                    </Typography>
                </Button>
            </Stack>

            <Box display="flex" justifyContent='space-between' color="#8DA8FF" gap="8px" alignItems="center" width={'100%'} mt={'8px'}>
                <TextField id="outlined-read-only-input" placeholder='Wallet Name' value={name} fullWidth
                    onChange={(e) => setName(e.target.value)} InputProps={{
                        style: { fontSize: '16px', padding: '0px', borderRadius: '8px', maxHeight: '40px' }
                    }} />
                <Button variant="contained" color="primary" style={{ padding: '8px 32px', borderRadius: '8px', fontWeight: '900' }} disabled={name.length === 0} onClick={handleSubmit}>
                    {!isLoading ? t('settings.createButton') : <CircularProgress size={24} color="inherit" />}
                </Button>
            </Box>

        </Box>
    );

}

export default NewWalletModal