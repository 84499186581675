import { create } from "zustand";

interface IUseIsMiningRunningStore {
  isMiningRunning: boolean;
  miningStatus: string;
  lastTimeListened: number;
  lostConnection: boolean;
  checkConnection: NodeJS.Timeout | undefined;
  setCheckConnection: (run: NodeJS.Timeout | undefined) => void;
  setIsMiningRunning: (run: boolean) => void;
  setMiningStatus: (run: string) => void;
  resetMiningStatus: () => void;
  updateTimeListened: () => void;
  resetTimeListened: () => void;
  toggleConnection: (status: boolean) => void;
}

export const useIsMiningRunning = create<IUseIsMiningRunningStore>((set) => ({
  isMiningRunning: false,
  miningStatus: `{"rate":"0","online":"Calculating...","status":200,"epoch":"0"}`,
  lastTimeListened: Date.now(),
  lostConnection: false,
  checkConnection: undefined,
  setCheckConnection: (run: NodeJS.Timeout | undefined) =>
    set((state) => ({ checkConnection: run })),
  setIsMiningRunning: (run: boolean) =>
    set((state) => ({ isMiningRunning: run })),
  setMiningStatus: (run: string) => set((state) => ({ miningStatus: run })),
  resetMiningStatus: () =>
    set((state) => ({
      isMiningRunning: false,
      miningStatus: `{"rate":"0","online":"Calculating...","status":200,"epoch":"0"}`,
    })),
  updateTimeListened: () => set((state) => ({ lastTimeListened: Date.now() })),
  resetTimeListened: () => set((state) => ({ lastTimeListened: 0 })),
  toggleConnection: (status: boolean) =>
    set((state) => ({ lostConnection: status })),
}));
