import { useState } from "react";
import { Typography, SvgIcon, Box, Link } from "@mui/material";
import { motion } from "framer-motion";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { ReactComponent as ExternalLinkIcon } from "../../../../../assets/external-link.svg";
import { ReactComponent as FireIcon } from "../../../../../assets/fire.svg";
import { SendIcon } from "../../../../UI/Icons/Icons";
import { useTranslation } from "react-i18next";

export default function DropdownTransactionHistory({
  transactionHistory,
}: any) {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation()
  const shortenHash = (hash: string) => {
    return hash.slice(0, 6) + "..." + hash.slice(-4);
  };

  return (
    <motion.nav
      initial={false}
      animate={isOpen ? "open" : "closed"}
      className='menu'
    >
      <motion.div
        whileTap={{ scale: 0.985 }}
        onClick={() => setIsOpen(!isOpen)}
        style={{
          width: "100%",
          backgroundColor:
            localStorage.getItem("mui-mode") === "light"
              ? "#d3ddff"
              : "#1f1f1f",
          borderRadius: "12px",
          padding: "16px 16px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <Typography
          color={
            localStorage.getItem("mui-mode") === "light" ? "#4a4a4a" : "#F6F1F2"
          }
          fontWeight={700}
          fontSize={'14px'}
          letterSpacing={'0.5px'}
          marginRight={"5px"}
        >
          {t('settings.transactionHistory')}
        </Typography>
        <motion.div
          variants={{
            open: { rotate: 180 },
            closed: { rotate: 0 },
          }}
          transition={{ duration: 0.2 }}
          style={{ originY: 0.55, display: 'flex' }}
        >
          <SvgIcon
            component={KeyboardArrowDownIcon}
            inheritViewBox
            sx={{ fontSize: 16 }}
          />
        </motion.div>
      </motion.div>
      <Box
        marginBottom={isOpen ? "20px" : "0"}
      >
        <motion.ul
          variants={{
            open: {
              clipPath: "inset(0% 0% 0% 0% round 10px)",
              transition: {
                type: "spring",
                bounce: 0,
                duration: 0.7,
                delayChildren: 0.3,
                staggerChildren: 0.05,
              },
            },
            closed: {
              clipPath: "inset(10% 50% 90% 50% round 10px)",
              transition: {
                type: "spring",
                bounce: 0,
                duration: 0.3,
              },
            },
          }}
          style={{
            pointerEvents: isOpen ? "auto" : "none",
            overflow: "auto",
            maxHeight: "150px",
            height: isOpen ? "fit-content" : "20px",
            scrollbarWidth: "thin",
            backgroundColor:
              localStorage.getItem("mui-mode") === "light"
                ? "#f8f8fa"
                : "#191919",
            marginTop: "8px",
          }}
        >
          {transactionHistory && transactionHistory?.length > 0 ?

            transactionHistory?.map((transaction: any, key: number) => {
              return (
                <motion.li
                  onClick={() => setIsOpen(false)}
                  style={{ cursor: "pointer", marginBottom: "2px" }}
                >
                  <Box key={key} flexShrink={0} sx={{ cursor: 'pointer', padding: { xs: '0 12px', md: '0 24px' } }} height="68px" display="flex" alignItems="center" justifyContent="space-between">
                    <Box display="flex" gap="20px" alignItems="center">
                      {transaction?.cCNTPBurn ?
                        <SvgIcon component={FireIcon} inheritViewBox sx={{ fontSize: 20 }} stroke={localStorage.getItem('mui-mode') === 'light' ? "#000000" : "#ffffff"} fill={localStorage.getItem('mui-mode') === 'light' ? "#000000" : "#ffffff"} /> : <SvgIcon component={SendIcon} inheritViewBox sx={{ fontSize: 32 }} />
                      }

                      <Box display="flex" flexDirection="column">
                        <Typography variant="subtitle1" fontSize={'14px'} fontWeight={'500'}>
                          <span style={{ fontWeight: '600' }}>
                            {new Date(transaction?.time).toLocaleString()}
                          </span>
                        </Typography>

                        {transaction?.cCNTPBurn ?
                          null : <Typography variant="subtitle1" fontSize={'14px'} fontWeight={'500'}>
                            to
                            <span style={{ fontWeight: '600' }}>
                              {" "}
                              {shortenHash(transaction?.to)}
                            </span>
                          </Typography>
                        }
                      </Box>
                    </Box>
                    <Box display="flex" gap="6px" alignItems="center" justifyContent="center">
                      {transaction?.cCNTPBurn ?
                        (
                          <Typography fontWeight={'500'} sx={{ fontSize: { sm: '14px', md: '16px' } }}>
                            {"- " + parseFloat(transaction?.value).toFixed(6) + ` ${transaction?.currency}`}
                          </Typography>
                        ) :
                        (
                          <Typography fontWeight={'500'} sx={{ fontSize: { sm: '14px', md: '16px' } }}>
                            {"- " + (parseFloat(transaction?.value) / Math.pow(10, 18)).toFixed(6) + ` ${transaction?.currency}`}
                          </Typography>
                        )
                      }
                      <Link href={`https://scan.conet.network/tx/${transaction?.transactionHash}`} target="_blank" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                        <SvgIcon component={ExternalLinkIcon} inheritViewBox sx={{ fontSize: 16 }} stroke={localStorage.getItem('mui-mode') === 'light' ? "#000000" : "#ffffff"} fill={localStorage.getItem('mui-mode') === 'light' ? "#000000" : "#ffffff"} />
                      </Link>
                    </Box>
                  </Box>
                </motion.li>
              );
            }) : (<motion.li
              onClick={() => setIsOpen(false)}
              style={{ cursor: "pointer", marginBottom: "2px" }}
            >
              <Box flexShrink={0} sx={{ cursor: 'pointer', padding: { xs: '0 12px', md: '0 24px' } }} height="68px" display="flex" alignItems="center" justifyContent="center">
                <Box display="flex" gap="6px" alignItems="center" justifyContent="center">
                  <Typography fontWeight={'500'} sx={{ fontSize: { sm: '14px', md: '16px' } }}>
                  {t('settings.noHistory')}
                  </Typography>
                </Box>
              </Box>
            </motion.li>
            )
          }
        </motion.ul>
      </Box>
    </motion.nav>
  );
}
