import { CircularProgress, styled as materialStyled } from '@mui/material'
import Typography from '@mui/material/Typography'
import { useState, useRef } from "react"
import InputBase from '@mui/material/InputBase'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import { LogoIcon } from "../../../UI/Logo/Logo"
import { Container } from "@mui/material"
import { ButtonLabel, ContainerButton, SpanTitle } from "./styles"
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { useTheme } from 'styled-components'
import { LogoLight, LogoDark } from './WelcomeToConet'
import PasswordResetForm from '../ResetPasswordForm'
import { toast } from 'react-toastify'

const CardStyle = materialStyled(Paper)(() => ({
    display: 'flex',
    textAlign: 'center',
    position: 'relative',
    borderRadius: '0.5rem',
    border: '1px solid #E4E2E4',
    width: '120px',
    height: '40px',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    justifyContent: 'center',
    padding: '8px 16px'
}))
const InputStyle = materialStyled(Paper)(() => ({
    display: 'flex',
    textAlign: 'center',
    borderRadius: '1rem',
    border: '1px solid #E4E2E4',
    maxWidth: '380px',
    width: '100%',
    height: '52px',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    justifyContent: 'center'
}))

interface CreateWalletProps {
    onClickStep: (number: number) => void
    recoverAccount: (SRP: string, passcode: string) => Promise<[string | null]>
}
const WriteYourSecretPhrase = ({ onClickStep, recoverAccount }: CreateWalletProps) => {
    const [loading, setLoading] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const theme = useTheme()

    const [show, setShow] = useState(false);
    const [confirmShow, setConfirmShow] = useState(false);

    const [phrase, setPhrase] = useState<string[]>(Array(12).fill(''));
    const [focusedIndex, setFocusedIndex] = useState<number | null>(null);
    const [activeAutoFocus, setActiveAutoFocus] = useState<boolean>(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState(false);
    const [passwordLengthError, setPasswordLengthError] = useState(false);
    const [passwordFocused, setPasswordFocused] = useState(false);
    const [confirmPasswordFocused, setConfirmPasswordFocused] = useState(false);
    // console.log(phrase, password, confirmPassword)
    
    const handleClick = () => {
        setShow(!show);
        setActiveAutoFocus(false)
    }
    const handleConfirmClick = () => setConfirmShow(!confirmShow);


    const handleWordChange = (index: number, value: string) => {
        const newPhrase = [...phrase];
        newPhrase[index] = value;
        setPhrase(newPhrase);
    };

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
        if(e.target.value.length >= 6){
            setPasswordLengthError(false);
        }else{
            setPasswordLengthError(true);
        }
    };

    const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setConfirmPassword(e.target.value);
        if(e.target.value != password){
            setPasswordError(true)
        }else{
            setPasswordError(false);
        }
    };

    async function handleSubmit() {
        setIsLoading(true)
        if (confirmPassword === password) {
            const res = await recoverAccount(phrase.join(' ').replace('\n', ''), password)
            if (res[0] === null) {
                toast.error('Invalid SRP', { position: "bottom-center", autoClose: 1500 })
                setIsLoading(false)
            } else {
                toast.success('Password updated', { position: "bottom-center", autoClose: 1500 })
                onClickStep(2)
                setIsLoading(false)
            }
        }
    }

    const handlePaste = (event, index) => {
        event.preventDefault();
        const pastedText = event.clipboardData.getData('text/plain');
        const words = pastedText.split(' ');
        const newInputValues = [...phrase];
        words.forEach((word, i) => {
            if (i < newInputValues.length) {
                newInputValues[i] = word;
            }
        });
        setPhrase(newInputValues);
    };

    return (
        <Container maxWidth='lg' sx={{ height: '100%', width: '100%', overflowX: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: { xs: '0', md: '1rem' } }}>
            <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', padding: 0, height: { xs: '100%', md: 'auto' }, maxWidth: '688px!important' }}>
                <Container sx={{ display: 'flex', flexDirection: 'column', padding: '1rem' }}>
                    <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        {localStorage.getItem('mui-mode') == 'dark' ? <LogoDark /> : <LogoLight />}
                        <Typography style={{ textTransform: 'uppercase', fontWeight: '700', fontSize: '14px' }}>Recover your Wallet</Typography>
                    </Container>
                    <Typography
                        style={{
                            fontSize: '32px',
                            fontWeight: '400',
                            marginTop: '40px',
                            maxWidth: '480px'
                        }}
                    >
                        Write or paste your phrase to reset your <SpanTitle>password</SpanTitle>
                    </Typography>
                    <Typography style={{ fontSize: '16px', fontWeight: '400', lineHeight: '24px' }}>Make sure you’re using the correct Secret Recovery Phrase before proceeding. You will not be able to undo this.</Typography>
                </Container>
                <Container sx={{ width: '100%' }}>
                    <Container sx={{ display: 'flex', flexWrap: 'wrap', gap: { xs: '8px 12px', md: '12px 16px', lg: '32px 16px' }, justifyContent: 'center', alignItems: 'center', padding: '0px!important', mt: { xs: '1.5rem', md: '0' } }}>
                        {phrase.map((item, index) =>
                            <>
                                <CardStyle key={index}>
                                    <div style={{ fontSize: '16px', fontWeight: '400' }}>
                                        <InputBase sx={{ width: '100%', mt: -1, flex: 1, fontSize: '1rem' }}
                                            onClick={() => setActiveAutoFocus(true)}
                                            value={phrase[index]}
                                            onChange={(e) => handleWordChange(index, e.target.value)}
                                            onPaste={(event) => handlePaste(event, index)}
                                        />
                                    </div>
                                    <div style={{ position: 'absolute', top: '-10px', left: '-5px', fontSize: '12px' }}>{index + 1}</div>
                                </CardStyle>
                            </>

                        )}
                    </Container>
                    <PasswordResetForm
                        loading={loading}
                        show={show}
                        confirmShow={confirmShow}
                        password={password}
                        confirmPassword={confirmPassword}
                        handlePasswordChange={handlePasswordChange}
                        handleConfirmPasswordChange={handleConfirmPasswordChange}
                        handleClick={handleClick}
                        handleConfirmClick={handleConfirmClick}
                        setPasswordFocused={setPasswordFocused}
                    />
                    {passwordLengthError && <Typography
                        style={{
                            fontSize: '12px',
                            fontWeight: '400',
                            maxWidth: '480px',
                            color:"red"
                        }}
                    >
                        Password needs to be at least 6 caracters long
                    </Typography>}
                    {passwordError && <Typography
                        style={{
                            fontSize: '12px',
                            fontWeight: '400',
                            maxWidth: '480px',
                            color:"red"
                        }}
                    >
                        Password and confirmation do not match
                    </Typography>}
                </Container>
                <Container>
                    <Container sx={{ display: 'flex', alignItems: 'center', padding: { xs: '0', md: '1rem' }, gap: '16px', margin: '100px 0', marginTop: '32px', width: { xs: '100%', md: '210px' } }}>
                        <ContainerButton onClick={() => handleSubmit()} disabled={isLoading || passwordError}>
                            <ButtonLabel>{!isLoading ? "Reset password" : <CircularProgress size={24} color="inherit" />}</ButtonLabel>
                        </ContainerButton>
                    </Container>
                </Container>
            </Container>
        </Container>

    )
}

export default WriteYourSecretPhrase