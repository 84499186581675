import React, { forwardRef, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  Typography,
  SvgIcon,
  Stack,
  Box,
  Checkbox,
  Select,
  MenuItem,
  Skeleton,
  ListSubheader,
  CircularProgress,
  Link,
  TextField,
  List,
  ListItem,
} from "@mui/material";
import { useTheme } from "styled-components";
import { loadCSS } from "fg-loadcss";
import {
  SuperNodeBannerContent,
  SuperNodeBannerWrapper,
} from "../styles/SuperNodeBanner";

import "../styles/superNodeBanner.css";
import { useTranslation } from "react-i18next";
import { IAppCardsProps } from "../../../NoDaemon/components/AppCard";
import AppCard from "../components/AppCard";

import conetianBg from '../assets/conetian-game.svg';
import fx168bg from '../assets/fx168bg.svg';
import conetProxy from '../assets/conet-proxy.svg';
import walletMining from '../assets/wallet-mining.svg';
import { IoIosArrowForward } from "react-icons/io";

export const MiningToolsPromoting = forwardRef((props, ref) => {
  const { t } = useTranslation()

  const dApps: IAppCardsProps[] = [
    {
      title: t('dApps.dApps.conetianTelegram.title'),
      description: t('dApps.dApps.conetianTelegram.description'),
      appImageBlack: conetianBg,
      appImageWhite: conetianBg,
      appUrl: 'https://t.me/conetian_bot',
      soon: false,
      renderTags: [t('dApps.discoverButton.gaming')],
      tags: ['gaming', 'mining']
    },
    {
      title: t('dApps.dApps.conetianWeb.title'),
      description: t('dApps.dApps.conetianWeb.description'),
      appImageBlack: conetianBg,
      appImageWhite: conetianBg,
      appUrl: 'https://theconetian.com/',
      soon: false,
      tags: ['gaming', 'mining'],
      renderTags: [t('dApps.discoverButton.gaming')]
    },
    {
      title: t('dApps.dApps.proxyExtension.title'),
      description: t('dApps.dApps.proxyExtension.description'),
      appImageWhite: conetProxy,
      appImageBlack: conetProxy,
      appUrl: 'https://chromewebstore.google.com/detail/conet-platform-extension/pkhkeliikkihkaolfcogfcbjmbehockd',
      soon: false,
      renderTags: [t('dApps.discoverButton.tool')],
      tags: ['tool']
    },
    {
      title: t('dApps.dApps.miningExtension.title'),
      description: t('dApps.dApps.miningExtension.description'),
      appImageWhite: walletMining,
      appImageBlack: walletMining,
      appUrl: 'https://chromewebstore.google.com/detail/wallet-mining-extension/ffccpciacnkgkkddhanggjlcajbbmmmn',
      soon: false,
      renderTags: [t('dApps.discoverButton.tool')],
      tags: ['tool', 'mining']
    },
    {
      title: t('dApps.dApps.fx168.title'),
      description: t('dApps.dApps.fx168.description'),
      appImageWhite: fx168bg,
      appImageBlack: fx168bg,
      appUrl: 'https://apps.apple.com/ca/app/fx168%E8%B4%A2%E7%BB%8F-%E5%AE%9E%E6%97%B6%E6%B7%B1%E5%BA%A6%E7%BE%8E%E8%82%A1%E8%B5%84%E8%AE%AF%E5%8F%8A%E5%9B%BD%E9%99%85%E6%97%B6%E6%94%BF%E6%96%B0%E9%97%BB/id535352246',
      soon: false,
      renderTags: [t('dApps.discoverButton.media')],
      tags: ['media', 'mining']
    },
  ]

  return (
    <SuperNodeBannerWrapper margin='auto' width={"100%"} >
      <Box p={"16px"} >
        <Box
          p={"16px"}
          display={'flex'}
          flexDirection={['column', 'column', 'row']}
          gap={'20px'}
          sx={{ overflowX: 'auto' }}
        >
          {/* Mining Tools Title column */}
          <Stack direction='column' gap={'24px'} maxWidth={'261px'} flex={'0 0 auto'}>
            <Typography
              sx={{
                textAlign: `left`,
                lineHeight: `3rem`,
              }}
              fontSize={["24px", "40px"]}
            >
              {t('dashboard.miningToolsPromoting.title1')}{" "}
              <span style={{ color: " #8DA8FF", fontWeight: 700 }}>
                {" "}
                {t('dashboard.miningToolsPromoting.title2')}{" "}
              </span>{" "}
              {t('dashboard.miningToolsPromoting.title3')}
            </Typography>

            <Typography fontSize='16px' fontWeight='600'>
              {t('dashboard.miningToolsPromoting.caption1')}
              <span style={{ color: " #8DA8FF", fontWeight: 700 }}>
                {" "}
                {t('dashboard.miningToolsPromoting.caption2')}{" "}
              </span>{" "}
              {t('dashboard.miningToolsPromoting.caption3')}{" "}
            </Typography>

            <Stack display={"flex"} flexDirection={'row'} alignItems={"center"} justifyContent={"flex-start"} gap={'4px'} color={'#8DA8FF'} style={{ cursor: 'pointer' }} onClick={() => window.open('https://doceng.conet.network/early-access-plans')}>
              <Typography fontSize='16px' fontWeight='700'>                                {t('dashboard.miningToolsPromoting.seeMore')}</Typography>
              <IoIosArrowForward fontSize="14px" />
            </Stack>
          </Stack>

          {/* Mining Tools column */}
          <Box flex={'0 0 auto'}>
            <Stack p={'16px'} direction='row' gap={'20px'}>
              {dApps.map((d, i) => {
                return (<AppCard key={i} appImageWhite={d.appImageWhite} appImageBlack={d.appImageBlack} appUrl={d.appUrl} title={d.title} description={d.description} />)
              })}
            </Stack>
          </Box>
        </Box>
      </Box>
    </SuperNodeBannerWrapper >
  );
});
