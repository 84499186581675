import React, { useState } from "react";
import "./makeHorizontal.css";
import ReactCardFlip from 'react-card-flip';
import { Box, Stack, SvgIcon, Typography } from "@mui/material";

import { ReactComponent as AddIcon } from "./assets/add.svg"

import { ReactComponent as ConetianIcon } from "./assets/conetian-claim.svg"
import { ReactComponent as GuardianIcon } from "./assets/guardians-claim.svg"
import { ReactComponent as MinerIcon } from "./assets/miners-claim.svg"
import { ReactComponent as LockedIcon } from "./assets/locked-icon.svg"
import { ReactComponent as UnlockedIcon } from "./assets/unlocked-icon.svg"

import { useTranslation } from "react-i18next";


const CardsList = () => {
    const { t } = useTranslation();

    const [card1Flipped, setCard1Flip] = useState<boolean>(false)
    const [card2Flipped, setCard2Flip] = useState<boolean>(false)
    const [card3Flipped, setCard3Flip] = useState<boolean>(false)

    const flipCard1 = () => {
        setCard1Flip(!card1Flipped)
    }

    const flipCard2 = () => {
        setCard2Flip(!card2Flipped)
    }

    const flipCard3 = () => {
        setCard3Flip(!card3Flipped)
    }

    const cards = [
        {
            "name": t('claim.guardian'),
            "image": GuardianIcon,
            "cta": `${t('claim.receive')} 10000 $CONET`,
            "detail": t('claim.forEachPlan'),
            "state": card1Flipped,
            "function": flipCard1,
            "description": [
                {
                    text: t('claim.card1.description.first'),
                    isLocked: false,
                },
            ],
            "descriptionDetail": t('claim.card1.descriptionDetail')
        },
        {
            "name": t('claim.conetian'),
            "image": ConetianIcon,
            "cta": `${t('claim.receive')} 1000 $CONET`,
            "detail": t('claim.forEachPlan'),
            "state": card2Flipped,
            "function": flipCard2,
            "description": [
                {
                    text: t('claim.card2.description.first'),
                    isLocked: false,
                },
                {
                    text: t('claim.card2.description.second'),
                    isLocked: true,
                },
            ],
            "descriptionDetail": t('claim.card2.descriptionDetail')
        },
        {
            "name": t('claim.miner'),
            "image": MinerIcon,
            "detail": t('claim.forEachPlan'),
            "cta": t('claim.airdropAllocation'),
            "state": card3Flipped,
            "function": flipCard3,
            "description": [
                {
                    text: t('claim.card3.description.first'),
                    isLocked: false,
                },
                {
                    text: t('claim.card3.description.second'),
                    isLocked: true,
                },
            ],
            "descriptionDetail": t('claim.card3.descriptionDetail')
        }
    ]

    const renderTextColor = (isLocked: boolean) => {
        if (localStorage.getItem('mui-mode') === "light") {
            return isLocked ? "#989899" : "#000000"
        } else {
            return isLocked ? "#989899" : "#F0F0F2"
        }
    }

    return (

        <Stack flexDirection={['column', 'row']} gap={1} justifyContent={'space-between'}>
            {cards.map((card => (
                <ReactCardFlip isFlipped={card.state} flipDirection="horizontal" containerStyle={{ width: ["100%"] }} >
                    <Box className="flipCard" bgcolor={localStorage.getItem('mui-mode') === "light" ? "#ffffff" : "#191919"} onClick={card.function} >
                        <Stack className="front">
                            <Typography mb={0} fontWeight={700} align="center" fontSize={'14px'} color={localStorage.getItem('mui-mode') === 'light' ? '#577DFF' : '#79F8FF'} minHeight={'36px'}>{card.name}</Typography>

                            <SvgIcon component={card.image} viewBox="0 0 40 40" sx={{
                                fontSize: '40px',
                                fill: 'none',
                                stroke: localStorage.getItem('mui-mode') === 'light' ? '#577DFF' : '#79F8FF',
                            }} />

                            <Stack gap={0} minHeight={'96px'}>
                                <Typography mb={0} fontWeight={700} align="center" fontSize={['18px', '24px']} color={localStorage.getItem('mui-mode') === "light" ? "#000000" : "#ffffff"}>{card.cta}</Typography>
                                <Typography mb={0} fontWeight={600} align="center" fontSize={['14px', '16px']} color={'#989899'}>{card.detail}</Typography>
                            </Stack>

                            <SvgIcon component={AddIcon} width={'24px'} viewBox="0 0 24 24" sx={{
                                fontSize: '24px',
                                fill: 'none',
                                stroke: localStorage.getItem('mui-mode') === 'light' ? '#577DFF' : '#79F8FF',
                            }} />

                        </Stack>
                    </Box>

                    <Box className="flipCard" bgcolor={localStorage.getItem('mui-mode') === "light" ? "#ffffff" : "#191919"}>
                        <Stack className="back" onClick={card.function} >
                            <Box display={'flex'} flexDirection={'column'} gap={'8px'}>
                                {card.description.map((description, index) => (
                                    <Box display="flex" flexDirection={'row'} alignItems="center" gap={1}>
                                        <SvgIcon component={description.isLocked ? LockedIcon : UnlockedIcon} width={32} viewBox="0 0 32 32" />

                                        <Typography fontWeight={500} align="left" fontSize={['12px', '14px']} color={renderTextColor(description.isLocked)}>{description.text}</Typography>
                                    </Box>
                                ))}
                            </Box>

                            <Typography fontWeight={500} align="left" fontSize={['10px', '10px']} color={'#989899'}>{card.descriptionDetail}</Typography>
                        </Stack>
                    </Box>
                </ReactCardFlip>
            )))}
        </Stack>
    )
};

export default CardsList;
