export const welcomeEn = {
    'create': 'Create your Wallet',
    'titleWhite': 'Welcome to',
    'titleBlue': 'CoNet',
    'createButton': 'Create Wallet',
    'recover': 'Recover Wallet',
}

export const welcomeZh = {
    'create': '创建您的钱包',
    'titleWhite': '欢迎来到',
    'titleBlue': 'CoNet',
    'createButton': '创建钱包',
    'recover': '恢复钱包',
}