export const createWalletEn = {
    'create': 'Create your Wallet',
    'titleWhite': 'Create a',
    'titleBlue': 'password',
    'newPassword': 'New password',
    'confirmPassword': 'Confirm password',
    'inputPassword': 'Minimum 6 character',
    'passwordError': 'Password and Confirm password must be the same', 
    'createButton': 'Create',
}

export const createWalletZh = {
    'create': '创建您的钱包',
    'titleWhite': '创建一个',
    'titleBlue': '暗号',
    'newPassword': '新密码',
    'confirmPassword': '确认密码',
    'inputPassword': '最少 6 个字符',
    'passwordError': '密码和确认密码必须相同', 
    'createButton': ' 创建',
}