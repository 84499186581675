import { DefaultTheme } from "styled-components";
import { fonts } from "./fonts";

const dark: DefaultTheme = {
  ui: {
    colors: {
      primary: "#8DA8FF",
      secondary: "#477EAD",
      dangerous: "#DF4759",
      text: {
        primary: "#FFF",
        secondary: "#636569",
        error: "#FF5B5D",
        disabled: "#C2C2C2",
        inverted: "#343434",
        neutral: `#929092`,
        white: "#F6F1F2",
        table: "#C8C6C8",
        subtittle: "#B1B1B2",
      },
      background: {
        foundation: "#1D1E24",
        elevationOne: "#17181f",
        elevationTwo: "#383b40",
        linear:
          "linear-gradient(90deg, rgba(215,117,255,1) 20%, rgba(141,168,255,1) 90%)",
        linearInverted:
          "linear-gradient(125deg, rgba(141,168,255,1) 29%, rgba(215,117,255,1) 70%)",
        tab: "#363E59",
        tableHeader: "#191919",
      },
      border: {
        light: "rgba(255, 255, 255, 0.1)",
        medium: "rgba(255, 255, 255, 0.5)",
        heavy: "rgba(255, 255, 255, 1)",
      },
      icon: {
        active: "#FFF",
        inactive: "#6E6E6E",
      },
      hover: "rgba(200,200,200,0.1)",
      globalBar: {
        background: "#000",
        color: "#fff",
      },
      backgroundDashboard: "#111113",
      pieChart: {
        primary: "#8D4BE1",
        secondary: "#D775FF",
      },
      pagination: {
        active: "#D775FF",
        inactive: "#303032",
        icon: "#C8C6C8",
      },
    },
    storybook: {
      canvasDecorator: {
        backgroundColor: "#404040",
        color: "#fff",
        controls: {
          backgroundColor: "#404040",
        },
      },
    },
    iconButton: {
      color: "#fff",
      invertedColor: "#000",
    },
    keypadKey: {
      numberColor: "#fff",
      alphabetColor: "#fff",
      basicColor: "#fff",
    },
    input: {
      color: "#fff",
      border: "#fff",
      placeholderColor: "#838383",
    },
    progress: {
      bar: {
        incomplete: "rgba(0, 0, 0, 0.2)",
        complete: "#00e500",
        separator: "rgba(255, 255, 255, 0.1)",
      },
      dot: {
        incomplete: "rgba(0, 0, 0, 0.2)",
        complete: "#fff",
      },
    },
    fontSizes: fonts,
  },
};

export default dark;
