import { Stack } from "@mui/material";
import styled from "styled-components";

export const BoostBannerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 1rem 5rem 1rem;
  background-color: ${localStorage.getItem('mui-mode') === 'light' ? "#FFFFFF" : "#17181F"};
  gap: 1.5rem;
  margin-top: 86px
`;

export const BoostBannerPanel = styled(Stack)`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  border-radius: 1rem;
  border: 1px solid ${localStorage.getItem('mui-mode') === 'light' ? "#FFFFFF" : "#474648"};
  background-color: ${localStorage.getItem('mui-mode') === 'light' ? "#FFFFFF" : "#262527"};
  justify-content: space-between;

  :first-child {
    color: ${(props) => props.theme.ui.colors.primary};
    text-transform: uppercase;
  }

  :nth-child(2) {
    max-width: 10rem;
    margin-bottom: 1.5rem;
  }

  :nth-child(3) {
    font-size: 12px;
    text-transform: uppercase;
    opacity: 0.7;
  }
`;
export const BoostBannerPanelRocket = styled(Stack)`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  border-radius: 1rem;
  border: 1px solid ${localStorage.getItem('mui-mode') === 'light' ? "#FFFFFF" : "#474648"};
  background-color: ${localStorage.getItem('mui-mode') === 'light' ? "#FFFFFF" : "#262527"};
  justify-content: space-between;

`;
export const RocketImage = styled.img`
  /* margin-right: 5rem; */
`;
