import { Box, Stack, Typography, useTheme } from '@mui/material';
import { PageContent } from '../../NoDaemon/styles';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { useState, useEffect } from "react";

import guardianNftLogo from "../miner/assets/guardianNftLogo.svg";
import conetianNftLogo from "../miner/assets/conetianNftLogo.svg";
import conetLogo from "../miner/assets/conetNft.svg";
import { useRefreshStore } from "../../../../API/useRefreshStore";
import { useUserProfile } from "../../../../API/useUserProfile"
import CardsList from "./CardsList";

export default function Claim() {
  const theme = useTheme();
  const walletStore = useRefreshStore(state => state.wallets)
  const { t } = useTranslation();

  const [cntp, setCntp] = useState<string>('0');
  const { userProfile, setUserProfile } = useUserProfile();

  useEffect(() => {
    async function calculateCurrentBalance() {
      const _cntp = walletStore.reduce ? walletStore.reduce((acc, cur) => acc + parseFloat(cur.tokens?.cCNTP?.balance ? cur.tokens?.cCNTP?.balance : 0), 0.000000) : 0.000000
      if (_cntp) {
        setCntp(Number(_cntp).toFixed(6).toString())
      }
    }
    if (walletStore && walletStore.length > 0) {
      calculateCurrentBalance()
    }
  }, [walletStore])

  function formatNumber(input: string) {
    return input ? input.padStart(4, '0') : '0000';
  }

  let cards = useMemo(() => ([
    {
      id: 1,
      title: '$CNTP',
      balance: cntp,
      available: false,
      image: conetLogo,
    }, {
      id: 2,
      title: t('claim.guardianNft'),
      balance: userProfile?.tokens?.GuardianPlan?.Guardian?.balance ? formatNumber(userProfile?.tokens?.GuardianPlan?.Guardian?.balance) : 0,
      available: false,
      image: guardianNftLogo
    }, {
      id: 3,
      title: t('claim.conetianNft'),
      balance: userProfile?.tokens?.ConetianPlan?.Conetian?.balance ? formatNumber(userProfile?.tokens?.ConetianPlan?.Conetian?.balance) : 0,
      available: false,
      image: conetianNftLogo,
    }
  ]), [walletStore])

  return (
    <Stack
      direction="row"
      justifyContent='center'
      alignItems="center"
      alignSelf={'flex-start'}
      width={['100%', '100%', 'calc(100% - 140px)']}
      paddingRight={['20px', '20px', '70px']}
      paddingLeft={['20px', '20px', '70px']}
      margin={'0 auto'}
    >
      <PageContent>
        <Box display={'flex'} gap={1} mb={4}>
          <Typography fontWeight={700} fontSize={'36px'}>{t('claim.title1')}</Typography>
          <Typography fontWeight={700} fontSize={'36px'} color={'#8DA8FF'}>{t('claim.title2')}</Typography>
        </Box>

        <Box
          borderRadius={8} style={{ background: 'linear-gradient(93.49deg, #D775FF 0%, #8DA8FF 100%)' }}
          padding="40px"
        >
          <Box maxWidth={['200px', '420px']} marginTop="40px">
            <Typography mb={2} fontWeight={700} fontSize={[22, '36px']} color={'#fff'}>{t('claim.bannerTitle')}</Typography>
            <Typography fontSize={[12, '16px']} color={'#fff'}>{t('claim.bannerCaption')}</Typography>
          </Box>
        </Box>

        <Stack marginTop="40px" gap="12px">
          <Typography fontWeight={700} fontSize="24px">{t('claim.yourAssets')}</Typography>
          <Stack direction="row" gap="16px" overflow="auto" padding="8px 0">
            {
              cards.map((card) => (
                <Stack direction="column" gap="16px" minWidth={["160px", "200px"]}>
                  <Stack border="1px solid #474648" bgcolor={theme.palette.mode === "light" ? "#FFFFFF" : "#000"} sx={{ borderRadius: "16px" }}>
                    <Box>
                      <img src={card.image} style={{ width: "100%", height: "auto", maxHeight: '175px', objectFit: "cover", borderTopRightRadius: "16px", borderTopLeftRadius: "16px" }} />
                    </Box>
                    <Box padding="16px" textAlign="center">
                      <Typography color={theme.palette.mode === "light" ? "#262626" : "#B1B1B2"}>{card.title}</Typography>
                      <Typography fontWeight="500" color={theme.palette.mode === "light" ? "#64C8CD" : "#79F8FF"}>{t('claim.balance')}: {card.balance}</Typography>
                    </Box>
                  </Stack>
                  {
                    card.available ? (
                      <button disabled style={{
                        background: "#8DA8FF", color: "#fff", fontWeight: "700", border: 0, borderRadius: "16px", width: "100%", padding: "20px",
                      }}>
                        Claim
                      </button>
                    ) : (
                      <button disabled style={{
                        background: theme.palette.mode === "light" ? "#F0F0F0" : "#262626", color: "#989899", fontWeight: "700", border: 0, borderRadius: "16px", width: "100%", padding: "12px", fontSize: '14px'
                      }}>
                        {t('claim.comingSoon')}
                      </button>
                    )
                  }
                </Stack>
              ))
            }
          </Stack>
        </Stack>
        <Stack marginTop={'32px'} flexDirection={'column'} gap={4}>
          <Typography fontWeight={700} fontSize="24px">{t('claim.claimAllocation')}</Typography>
          <CardsList />
        </Stack>
      </PageContent>


    </Stack>
  );
}