import { useEffect, useState } from "react";
import { useTheme } from "styled-components";
import {
    CircularProgress,
    TextField,
    Typography,
    Stack,
    Box,
    Button,
    Link,
    Modal,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Badge,
    SvgIcon,
} from "@mui/material";

import arrowWhite from "../../../../../assets/logo/arrow-white-down.svg"
import arrowBlack from '../../../../../assets/logo/arrow-black-down.svg'

export function ReferralsMinerRow(props: any) {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const { colors } = useTheme().ui;

    return (
        <>

            <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
                {Object.values(props.row).length > 0 && typeof props.row !== 'string' ?
                    (
                        <TableCell component='th' scope="row" style={{ display: 'flex', alignItems: 'center' }}>
                            <img src={localStorage.getItem('mui-mode') === 'light' ? arrowBlack : arrowWhite} style={{ cursor: 'pointer', marginLeft: props.margin || '0px', color: '#F6F1F2' }} onClick={() => setIsOpen(!isOpen)} />
                            <p>{Object.keys(props.row)}</p>
                        </TableCell>
                    ) :
                    <TableCell component='th' scope="row" style={{ display: 'flex', alignItems: 'center' }}>
                        <p style={{ marginLeft: '60px' }}>{props.row}</p>
                    </TableCell>
                }
                <TableCell>{props.row.type || 'User'}</TableCell>
                <TableCell>
                    <Stack direction="row" alignItems="center" gap=".25rem">
                        <Box
                            mt="2px"
                            width=".5rem"
                            height=".5rem"
                            borderRadius="50%"
                            bgcolor={
                                props.row.status === `active`
                                    ? colors.primary
                                    : colors.text.error
                            }
                        />
                        {props.row.status}
                    </Stack>
                </TableCell>
                <TableCell align="right">{typeof props.row == 'string' ? '1%' : props.comission}</TableCell>
            </TableRow>
            {isOpen && (
                <>
                    {Object.values(props.row).map((value, index) => {
                        if (Array.isArray(value)) {
                            return (
                                <>
                                    {value.map((item, idx) => (
                                        <ReferralsMinerRow key={idx} row={item} index={index} margin={'20px'} comission={'3%'} />
                                    ))}
                                </>
                            );
                        }
                        return null;
                    })}
                </>
            )}
        </>
    )

    /*return (
        <>
            {
                Object.keys(props.row).join('').length > 41 ? (
                    <TableRow
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                        {Object.values(props.row).length > 0 && typeof props.row !== 'string' ? (
                            <TableCell component='th' scope="row" style={{ display: 'flex', alignItems: 'center' }}>
                                <img src={arrow} style={{ cursor: 'pointer', marginLeft: props.margin || '0px' }} onClick={() => setIsOpen(!isOpen)} />
                                <p>{Object.keys(props.row)}</p>
                            </TableCell>
                        ) : (
                            <TableCell component='th' scope="row" style={{ display: 'flex', alignItems: 'center' }}>
                                <p style={{ marginLeft: '60px' }}>{props.row}</p>
                            </TableCell>
                        )}
                        <TableCell>{props.row.type || 'User'}</TableCell>
                        <TableCell>
                            <Stack direction="row" alignItems="center" gap=".25rem">
                                <Box
                                    mt="2px"
                                    width=".5rem"
                                    height=".5rem"
                                    borderRadius="50%"
                                    bgcolor={
                                        props.row.status === `active`
                                            ? colors.primary
                                            : colors.text.error
                                    }
                                />
                                {props.row.status}
                            </Stack>
                        </TableCell>
                        <TableCell align="right">{props.row.commision || '-'}</TableCell>
                    </TableRow>
                ) : null
            }
            {isOpen && Object.keys(props.row).join('').length > 41 && (
                <>
                    {Object.values(props.row).map((row, index) => (
                        <ReferralsMinerRow key={index} row={row} index={index} margin={'20px'} />
                    ))}
                </>
            )}
        </>
    )*/

}