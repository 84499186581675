import { Box, Stack, Typography, useTheme } from '@mui/material';
import { PageContent } from '../../NoDaemon/styles';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { useState, useEffect } from "react";

import guardianNftLogo from "./assets/guardianNftLogo.svg";
import conetianNftLogo from "./assets/conetianNftLogo.svg";
import conetianAgentNftLogo from "./assets/conetianAgentNftLogo.png";
import guardianReferralNftLogo from "./assets/guardianReferralNftLogo.png";
import conetLogo from "../miner/assets/conetNft.svg";
import { useRefreshStore } from "../../../../API/useRefreshStore";
import { useUserProfile } from "../../../../API/useUserProfile"
import WalletPool from './WalletPool';

export default function AdminMonitor() {
  const theme = useTheme();
  const { t } = useTranslation();

  const monitoredWallets = useRefreshStore(state => state.monitoredWallets)


  let cards = useMemo(() => {

    const sums = { cntp: 0, conet: 0, guardianNft: 0, conetianNft: 0, guardianReferralNft: 0, conetianReferralNft: 0 }

    monitoredWallets.forEach(wallet => {
      sums.cntp += parseFloat(wallet.assets.cntp.balance)
      sums.conet += parseFloat(wallet.assets.conet.balance)
      sums.guardianNft += parseInt(wallet.assets.guardianNft.balance)
      sums.conetianNft += parseInt(wallet.assets.conetianNft.balance)
      sums.guardianReferralNft += parseInt(wallet.assets.guardianReferralNft.balance)
      sums.conetianReferralNft += parseInt(wallet.assets.conetianReferralNft.balance)
    })

    return ([
      {
        id: 1,
        title: '$CNTP',
        balance: sums.cntp,
        available: false,
        image: conetLogo,
      },
      {
        id: 2,
        title: '$CONET',
        balance: sums.conet,
        available: false,
        image: conetLogo,
      },
      {
        id: 2,
        title: t('claim.guardianNft'),
        balance: sums.guardianNft,
        available: false,
        image: guardianNftLogo
      },
      {
        id: 4,
        title: t('claim.conetianNft'),
        balance: sums.conetianNft,
        available: false,
        image: conetianNftLogo,
      },
      {
        id: 5,
        title: 'Guardian Referral',
        balance: sums.guardianReferralNft,
        available: false,
        image: guardianReferralNftLogo
      },
      {
        id: 6,
        title: 'Conetian Agent',
        balance: sums.conetianReferralNft,
        available: false,
        image: conetianAgentNftLogo
      },
    ])
  }, [monitoredWallets])

  return (
    <Stack
      direction="row"
      justifyContent='center'
      alignItems="center"
      alignSelf={'flex-start'}
      width={['100%', '100%', 'calc(100% - 140px)']}
      paddingRight={['20px', '20px', '70px']}
      paddingLeft={['20px', '20px', '70px']}
      margin={'0 auto'}
    >
      <PageContent>
        <Box display={'flex'} gap={1} mb={4}>
          <Typography fontWeight={700} fontSize={'36px'}>Admin</Typography>
          <Typography fontWeight={700} fontSize={'36px'} color={'#8DA8FF'}>Monitor</Typography>
        </Box>

        <WalletPool />

        <Stack marginTop="40px" gap="12px">
          <Typography fontWeight={700} fontSize="24px">Total Assets</Typography>
          <Stack direction="row" gap="16px" overflow="auto" padding="8px 0">
            {
              cards.map((card) => (
                <Stack direction="column" gap="16px" minWidth={["160px", "200px"]}>
                  <Stack border="1px solid #474648" bgcolor={theme.palette.mode === "light" ? "#FFFFFF" : "#000"} sx={{ borderRadius: "16px" }}>
                    <Box>
                      <img src={card.image} style={{ width: "100%", height: "auto", maxHeight: '175px', objectFit: "cover", borderTopRightRadius: "16px", borderTopLeftRadius: "16px" }} />
                    </Box>
                    <Box padding="16px" textAlign="center">
                      <Typography color={theme.palette.mode === "light" ? "#262626" : "#B1B1B2"}>{card.title}</Typography>
                      <Typography fontWeight="500" color={theme.palette.mode === "light" ? "#64C8CD" : "#79F8FF"}>{t('claim.balance')}: {card.balance.toFixed(6)}</Typography>
                    </Box>
                  </Stack>
                </Stack>
              ))
            }
          </Stack>
        </Stack>
      </PageContent>
    </Stack>
  );
}