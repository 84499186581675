import styled from "styled-components";
import Typography from "@mui/material/Typography";

export const PageContent = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: space-around;
  padding-top: 2rem;

  @media (max-width: 830px) {
    justify-content: flex-start;
    gap: 4rem;
  }
`;

export const PageInternalContent = styled.div<{ applyBorderTop?: boolean }>`
  display: flex;
  flex-direction: column;
  border-top: ${(props) => (props.applyBorderTop ? `1px solid #cccccc55` : ``)};
  gap: 1rem;
`;

export const OnlyMobileText = styled(Typography)`
  color: ${(props) => props.theme.ui.colors.text.neutral};
`;

export const DaemonDownloadWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;

  @media (max-width: 830px) {
    flex-direction: column;
  }
  @media ((min-width: 500px) and (max-width: 830px)) {
    margin: 0 2rem;
    width: calc(100% - 4rem);
  }
`;

export const DaemonDownloadButton = styled.button`
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0.5rem;
  border-radius: 1rem;
  height: 4.5rem;
  background: ${(props) => props.theme.ui.colors.primary};
  color: ${(props) => props.theme.ui.colors.text.inverted};
  padding: 0 1.25rem;
  cursor: pointer;
  &:hover {
    filter: brightness(0.9);
  }
`;

export const DaemonDownloadLabel = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

export const DaemonDownloadIconWrapper = styled.div`
  display: flex;
  font-size: 1.5rem;
`;
