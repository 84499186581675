import { Typography } from "@mui/material";

interface INftCard {
    nftImage: string
    title: string;
    balance?: string;
    nodeNftId?: string;
    totalSupply?: string;
}

export default function NftCard({ nftImage, title, balance, nodeNftId, totalSupply }: INftCard) {

    return (
        <div style={{ width: '130px', display: 'flex', flexDirection: 'column' }}>
            <div style={{ width: '100%' }}>
                <img src={nftImage} alt="logoImge" width='100%' style={{ overflow: 'hidden', zIndex: '-1', borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }} />

                <div style={{ marginTop: '-7px', backgroundColor: '#000', border: '1px solid #474648', borderBottomLeftRadius: '8px', borderBottom: '1px solid #474648', borderBottomRightRadius: '8px', height: '70px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>

                    <Typography fontSize='14px' color={'#B1B1B2'} textAlign={'center'} fontWeight={700} pt={1}>{title}</Typography>

                    {
                        balance && <Typography fontSize='12px' color={'#B1B1B2'} textAlign={'center'} fontWeight={500} pb={1}>Balance: {balance}</Typography>
                    }

                    {
                        nodeNftId && <Typography fontSize='12px' color={'#B1B1B2'} textAlign={'center'} fontWeight={500} pb={1}>#{nodeNftId}</Typography>
                    }
                </div>
            </div>

            {totalSupply &&
                <div style={{ padding: '5px 10px' }}>
                    <Typography fontSize='8px'> <span style={{ fontWeight: 'bold' }}>{totalSupply}</span> NFTs available for purchase</Typography>
                </div>
            }
        </div>
    )

}