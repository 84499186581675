import { createContext, useContext, useMemo } from 'react';
import { useFetchData } from './refresh';

type FetchDataType = {
    wallet: any[];
    referees: any[];
    leaderboard: any;
};

const initialState: FetchDataType = {
    wallet: [],
    referees: [],
    leaderboard: {},
};

const DataContext = createContext<FetchDataType>(initialState);

export const DataProvider = ({ children }: any) => {
    const data = useFetchData()

    const value = useMemo(() => ({ ...data }), [data]);

    return (
        <DataContext.Provider value={value}>
            {children}
        </DataContext.Provider>
    );
};

export const useData = () => useContext(DataContext);