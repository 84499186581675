import { DefaultTheme } from "styled-components";
import { fonts } from "./fonts";

const light: DefaultTheme = {
  ui: {
    colors: {
      primary: "#577DFF",
      secondary: "#396FC0",
      dangerous: "#DF4759",
      text: {
        primary: "#343434",
        secondary: "#636569",
        error: "#FF5B5D",
        disabled: "#C2C2C2",
        inverted: "#FFF",
        neutral: `#5f5e60`,
        white: '#111113',
        table: '#535254',
        subtittle: '#535254',
      },
      background: {
        foundation: "#fff",
        elevationOne: "#f6f8ff",
        elevationTwo: "#fff",
        linear:
          "linear-gradient(95deg, rgba(141,75,225,1) 20%, rgba(87,125,255,1) 90%)",
        linearInverted:
          "linear-gradient(125deg, rgba(87, 125, 255, 1) 29%, rgba(141, 75, 225, 1) 70%)",
        tab: '#EFF2FF',
        tableHeader: '#F6F8FF'
      },
      border: {
        light: "rgba(0,0,0,0.1)",
        medium: "rgba(0,0,0,0.5)",
        heavy: "rgba(0,0,0,1)",
      },
      icon: {
        active: "#343434",
        inactive: "#C2C2C2",
      },
      hover: "rgba(0, 0, 0, 0.1)",
      globalBar: {
        background: "#597CB3",
        color: "#000",
      },
      backgroundDashboard: "#fff",
      pieChart: {
        primary: '#577DFF',
        secondary: '#79F8FF'
      },
      pagination: {
        active: '#577DFF',
        inactive: '#F3F0F2',
        icon: '#929092'
      }
    },
    storybook: {
      canvasDecorator: {
        backgroundColor: "#EFEFEF",
        color: "#000",
        controls: {
          backgroundColor: "#E6E6E6",
        },
      },
    },
    iconButton: {
      color: "#333332",
      invertedColor: "#fff",
    },
    keypadKey: {
      numberColor: "#333332",
      alphabetColor: "rgba(0,0,0,0.5)",
      basicColor: "#333332",
    },
    input: {
      color: "#333332",
      border: "#333332",
      placeholderColor: "#dcdcdc",
    },
    progress: {
      bar: {
        incomplete: "rgba(0, 0, 0, 0.15)",
        complete: "#00e500",
        separator: "rgba(0, 0, 0, 0.1)",
      },
      dot: {
        incomplete: "rgba(0, 0, 0, 0.15)",
        complete: "#4d4d4c",
      },
    },
    fontSizes: fonts,
  },
};

export default light;
