import styled from "styled-components";

export const ContainerButton = styled.button`
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 1rem;
  background: ${(props) => props.theme.ui.colors.primary};
  color: ${(props) => props.theme.ui.colors.text.inverted};
  padding: 1rem;
  cursor: pointer;
  &:hover {
    filter: brightness(0.9);
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export const ContainerTransparentButton = styled.button`
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 1rem;
  background: #00000000;
  color: ${(props) => props.theme.ui.colors.text.primary};
  padding: 1rem;
  cursor: pointer;
  &:hover {
    filter: brightness(0.9);
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export const ButtonLabel = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
`;

export const SpanTitle = styled.span`
  color: ${(props) => props.theme.ui.colors.primary};
`;

export const LinkText = styled.p`
  color: ${(props) => props.theme.ui.colors.primary};
  margin-top: 24px;
  /* padding: 8px 16px; */
  font-size: 14px;
  font-weight: 700;
`;
