import { Box, Stack, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import '../styles/style.css';
import { Button } from "@mui/base";
import { useTranslation } from "react-i18next";


interface ISelectedNfts {
  title: string;
  quantity: number;
}
interface IPlanCard {
  image: string;
  plan: string;
  title: string;
  planType: 'nft' | 'supernode';
  selectedPlan: string;
  selectedNfts?: ISelectedNfts[]
  setSelectedNfts?: React.Dispatch<React.SetStateAction<ISelectedNfts[]>>
  setSelectedPlan: React.Dispatch<React.SetStateAction<any>>;
}

export default function PlanCard({
  image,
  title,
  selectedPlan,
  setSelectedPlan,
  plan,
  planType,
  selectedNfts,
  setSelectedNfts
}: IPlanCard) {

  const [quantity, setQuantity] = useState<number>(0)

  const { t } = useTranslation()

  function updateNftsToBuy() {
    if (selectedNfts && setSelectedNfts) {
      const newItem = {
        title: plan, quantity
      };

      let updatedNftsToBuy = selectedNfts.filter((i) => i.title !== plan);

      if (quantity > 0) {
        updatedNftsToBuy = [...updatedNftsToBuy, newItem];
      }

      setSelectedNfts(updatedNftsToBuy);
    }
  }

  useEffect(() => {
    updateNftsToBuy();
  }, [quantity]);

  useEffect(() => {
    const handleMouseMove = (e: any) => {
      const rect = e.target.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;

      const xPercent = (x / rect.width) * 100;
      const yPercent = (y / rect.height) * 100;

      e.target.style.setProperty("--x", xPercent + "%");
      e.target.style.setProperty("--y", yPercent + "%");
    };

    const cards = document.querySelectorAll(".card");
    cards.forEach((card) => {
      card.addEventListener("mousemove", handleMouseMove);

      return () => {
        card.removeEventListener("mousemove", handleMouseMove);
      };
    });
  }, []);

  return (
    <Box display={'flex'} flexDirection={'column'}>
      <motion.div whileTap={{ scale: 1.0125 }} className="cardAbove">
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          onClick={() => setSelectedPlan(plan)}
          sx={{
            zIndex: '2',
            cursor: 'pointer',
          }}
        >
          <Stack
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            gap='8px'
            width={'186px'}
            maxWidth={'186px'}
          >
            <img src={image} style={{ width: '100%', maxWidth: '100%', borderRadius: '13px', border: '1px solid #676768' }} />

            <Stack display={'flex'} flexDirection={'row'} gap={1} flexWrap={'wrap'}>
              <Typography color={localStorage.getItem('mui-mode') === 'light' ? '#000000' :
                '#b1b1b2'} fontSize={20}>{t(`dashboard.buyCard.${title}`)}</Typography>
            </Stack>
          </Stack>
        </Box>
      </motion.div >
    </Box>
  );
}
