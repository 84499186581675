import { Icon, styled as materialStyled } from '@mui/material'
import { useIntl } from "react-intl"
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import React, { HTMLAttributes, useState, useEffect, SyntheticEvent, ReactNode } from "react"
import Slide from '@mui/material/Slide'
import InputBase from '@mui/material/InputBase'
import Paper from '@mui/material/Paper'
import Divider from '@mui/material/Divider'
import DirectionsIcon from '@mui/icons-material/Directions'
import ErrorIcon from '@mui/icons-material/Error'
import LoadingButton from '@mui/lab/LoadingButton'
import CircularProgress from '@mui/material/CircularProgress'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import useAppState from "../../../../store/appState/useAppState"
import { createPasscode } from '../../../../API/index'
import { LogoIcon } from "../../../UI/Logo/Logo"
import { Container } from "@mui/material"
import { ButtonLabel, ContainerButton, SpanTitle } from "./styles"
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { LogoLight, LogoDark } from './WelcomeToConet'
import { useTranslation } from 'react-i18next'

const InputStyle = materialStyled(Paper)(() => ({
    display: 'flex',
    textAlign: 'center',
    borderRadius: '1rem',
    border: '1px solid #E4E2E4',
    width: '380px',
    height: '52px',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    justifyContent: 'center'
}))

interface CreateWalletProps {
    onClickStep: (number: number) => void,
    createAccount: (password: string) => any,
    setListSrp: (list: string[]) => void,
}
const CreateWallet = ({ onClickStep, createAccount, setListSrp }: CreateWalletProps) => {
    const {
        locale,
        setLocale,
        isUnlocked
        //setTheme,
        //theme
    } = useAppState()
    const intl = useIntl()
    const [loading, setLoading] = useState(false)
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [passwordError, setPasswordError] = useState(false)
    const [show, setShow] = useState(false);
    const [confirmShow, setConfirmShow] = useState(false);

    const handleClick = () => setShow(!show);
    const handleConfirmClick = () => setConfirmShow(!confirmShow);

    const keyUpHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const key = event.key
        if (key == 'Enter') {
            return processCreatePassword()
        }
    }
    
    const { t } = useTranslation()

    useEffect(() => {
        if (confirmPassword !== password) {
            setPasswordError(true);
        } else {
            setPasswordError(false);
        }
    }, [confirmPassword, password]);

    const processCreatePassword = async () => {
        setLoading(true)
        // const [yyy, rrr] = await createPasscode(password, locale)
        const result = await createAccount(password)
        // location.reload()
        if (result) {
            setListSrp(result.split(' '))
            onClickStep(3)
        }
    }
    const validatePassword = () => {
        return password.length > 5 && password === confirmPassword
    }

    return (
        <Container maxWidth='lg' sx={{ height: {sx: '90vh', md: '100vh'}, width: '100%', overflow: 'hidden', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: { xs: '0', md: '1rem' }}}>
            <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', height: '100vh', padding: { xs: '30px', md: '1rem' } }}>
                <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: { xs: '0', md: '1rem' } }}>
                {localStorage.getItem('mui-mode') == 'dark' ? <LogoDark /> : <LogoLight />}
                    <Typography style={{ textTransform: 'uppercase', fontWeight: '700', fontSize: '14px' }}> {t('createWallet.create')}</Typography>
                    <Typography
                        style={{
                            fontSize: '32px',
                            fontWeight: '400',
                            marginTop: '40px',
                        }}
                    >
                         {t('createWallet.titleWhite')} <SpanTitle> {t('unlockWallet.titleBlue')}</SpanTitle>
                    </Typography>
                </Container>
                <Container sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '1rem' }}>
                    <Typography style={{ fontSize: '16px', fontWeight: '400', width: '100%', maxWidth: '380px' }}> {t('createWallet.newPassword')}</Typography>
                    <InputStyle style={{ width: '100%', maxWidth: '380px' }}>
                        <InputBase
                            sx={{ width: '100%', ml: 1, flex: 1, fontSize: '1rem', padding: '0.7rem 0.5rem 0.5rem 1rem' }}
                            disabled={loading}
                            id="outlined-password-input"
                            placeholder= {t('createWallet.inputPassword')}
                            type={show ? 'text' : "password"}
                            onChange={e => {
                                setPassword(e.target.value)
                            }}
                            error={passwordError}
                            onKeyUp={keyUpHandler}
                        />
                        <>
                            <IconButton tabIndex={-1} onClick={handleClick}>
                                {show ?
                                    <VisibilityOutlinedIcon /> :
                                    <VisibilityOffOutlinedIcon />
                                }
                            </IconButton>
                        </>
                    </InputStyle>
                    <Typography style={{ fontSize: '16px', fontWeight: '400', width: '100%', maxWidth: '380px', marginTop: '24px' }}> {t('createWallet.confirmPassword')}</Typography>

                    <InputStyle style={{ width: '100%', maxWidth: '380px' }}>
                        <InputBase
                            sx={{ width: '100%', ml: 1, flex: 1, fontSize: '1rem', padding: '0.7rem 0.5rem 0.5rem 1rem' }}
                            disabled={loading}
                            id="outlined-password-input"
                            placeholder={t('createWallet.inputPassword')}
                            type={confirmShow ? 'text' : "password"}
                            onChange={e => {
                                setConfirmPassword(e.target.value)
                            }}
                            error={passwordError}
                            onKeyUp={keyUpHandler}
                        />
                        <>
                            <IconButton onClick={handleConfirmClick}>
                                {confirmShow ?
                                    <VisibilityOutlinedIcon /> :
                                    <VisibilityOffOutlinedIcon />
                                }
                            </IconButton>
                        </>
                    </InputStyle>
                    {passwordError ?
                        <Container style={{ width: '100%', maxWidth: '380px', padding: '0' }}>
                            <Typography textAlign={'left'} fontSize={'14px'} marginTop={'4px'} color={'red'}> {t('createWallet.passwordError')}</Typography>
                        </Container>
                        :
                        <></>
                    }
                </Container>

                <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '4rem' }}>
                    <ContainerButton style={{ width: '100%', maxWidth: '380px' }} disabled={!validatePassword()} onClick={processCreatePassword}>
                        {
                            loading
                                ? <CircularProgress size={24} color="inherit" />
                                : <ButtonLabel> {t('createWallet.createButton')}</ButtonLabel>
                        }
                    </ContainerButton>
                </Container>
            </Container>
        </Container>
    )
}

export default CreateWallet