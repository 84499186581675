import { Button } from '@mui/base';
import { ArrowForwardRounded } from '@mui/icons-material';
import { Stack, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

export interface IAppCardsProps {
    appImageWhite: string;
    appImageBlack: string;
    title: string;
    description: string;
    appUrl?: string;
    renderTags: string[];
    tags: string[];
    soon: boolean;
}


export default function AppCard({ appImageWhite, appImageBlack, title, appUrl, renderTags, tags, soon, description }: IAppCardsProps) {
    const theme = useTheme()
    const { t } = useTranslation()

    return (
        <Stack
            width={'261px'}
            height={'fit-content'}
            style={{ cursor: !soon ? 'pointer' : 'default' }}
            bgcolor={localStorage.getItem('mui-mode') === 'light' ? "#fff" : "#262527"}
            border={`1px solid ${localStorage.getItem('mui-mode') === 'light' ? "#E4E2E4" : "#474648"}`}
            borderRadius={'16px'}
        >
            <img src={localStorage.getItem('mui-mode') === 'light' ? appImageWhite : appImageBlack} alt="logoImge" width={'100%'} />

            <div style={{ padding: '24px' }}>

                <Typography color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#fff"} fontWeight={700}>{title}</Typography>

                <div style={{ display: 'flex', flexDirection: 'row', gap: '8px', marginTop: '8px' }}>
                    {renderTags.map((t, i) => {
                        return <Typography color={'#858586'} fontWeight={700} key={i}>{t.charAt(0).toUpperCase() + t.slice(1)}</Typography>
                    })}
                </div>

                <div style={{ marginTop: '16px' }}>
                    <Typography color={"#858586"} fontWeight={400} fontSize={'12px'}>{description}</Typography>
                </div>

                {appUrl && (
                    <Button
                        style={{ marginTop: '24px', background: theme.palette.mode === "dark" ? "#8DA8FF" : "#577DFF", color: "#ffffff", padding: "12px 20px", fontWeight: "700", width: "100%", border: "none", borderRadius: "16px", display: "flex", justifyContent: "space-between", alignItems: "center", gap: "16px" }}
                        onClick={() => window.open(appUrl, '_blank')}
                    >
                        {t('dApps.dApps.getApp')}
                        <ArrowForwardRounded />
                    </Button>
                )}

                {soon && (
                    <Typography color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#fff"} fontWeight={700} mt={4}>{t('dApps.dApps.comingSoon')}</Typography>
                )}
            </div>
        </Stack >
    )
}