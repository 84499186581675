export const copyEn = {
    'create': 'Create your Wallet',
    'titleWhite': 'Copy your Secret',
    'titleBlue': 'Recovery Phrase',
    'subTitle': 'Your Secret Recovery Phrase makes it easy to back up and restore your account.',
    'never': 'Never lose your Secret Recovery Phrase',
    'copyPhrase': 'Copy phrase',
    'enter': 'Enter CoNET',
    'warning': 'WARNING: Never disclose your Secret Recovery Phrase. Anyone with this phrase can take your Ether forever.'
}

export const copyZh = {
    'create': '创建您的钱包',
    'titleWhite': '复制您的秘密',
    'titleBlue': '恢复短语',
    'subTitle': '您的恢复密语可让您轻松备份和恢复账户。',
    'never': '永不丢失您的恢复密语',
    'copyPhrase': '复制短语',
    'enter': '进入 CoNET',
    'warning': '警告：切勿泄露您的恢复密语。任何人只要知道这个短语，就能永远拿走你的以太币。'
}