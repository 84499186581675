import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { Typography, Stack } from "@mui/material";
import { useTheme } from "styled-components";
import { loadCSS } from "fg-loadcss";

import {
  BoostBannerPanel,
  BoostBannerPanelRocket,
  BoostBannerWrapper,
  RocketImage,
} from "../styles/BoostBanner";
// import rocketPlatformPNG from "../../../../../assets/images/rocket-platform.png";
import rocketPlatformPNG from "../../../../../assets/images/rocket-platform-2.png";
import { useTranslation } from "react-i18next";

export const BoostBanner: React.FC = () => {
  const intl = useIntl();
  const { t } = useTranslation()
  const { colors } = useTheme().ui;


  useEffect(() => {

    const fetchData = async () => {
      if (!active) return;

      const node = loadCSS(
        "https://use.fontawesome.com/releases/v6.5.1/css/all.css",
        //@ts-ignore
        document.querySelector("#font-awesome-css") || document.head.firstChild
      );

      return () => {
        active = false;
        node.parentNode!.removeChild(node);
      };
    };

    let active = true;
    fetchData();
  }, []);

  useEffect(() => {
    const element = document.getElementById(`airdrop`);
    if (!element) return;

    element.innerHTML = element.innerHTML.replace(",", `<br />`);
    element.innerHTML = element.innerHTML.replace(";", `<br />`);
  }, []);

  return (
    <BoostBannerWrapper
      style={{
        backgroundColor: localStorage.getItem('mui-mode') === 'light' ? "#F6F8FF" : "#17181F",
        marginTop: '100px',
        paddingBottom: '100px'
      }}
    >
      <Typography
        color="red"
        textTransform="uppercase"
        fontSize="14px"
        mb="-.5rem"
      >
        {t('dashboard.nodeFooter.soon')}
      </Typography>

      <Typography
        fontSize="38px"
        lineHeight="3rem"
        maxWidth="28rem"
        textAlign="center"
      >
        {t('dashboard.nodeFooter.title')}
      </Typography>

      <Stack
        direction={{ xs: "column", sm: "row" }}
        gap="1rem"
        justifyContent="center"
        maxWidth="1040px"
        width="100%"
        margin="auto"
      // padding={{ xs: `0`, sm: "0 2rem" }}
      >
        <BoostBannerPanel
          style={{
            border: '1px solid',
            backgroundColor: localStorage.getItem('mui-mode') === 'light' ? "#FFFFFF" : "#262527",
            borderColor: localStorage.getItem('mui-mode') === 'light' ? "#E4E2E4" : "#474648"
          }}
        >
          <Typography>
            {t('dashboard.nodeFooter.band')}
          </Typography>
          <Typography>
            {t('dashboard.nodeFooter.bandShare')}
          </Typography>
          <Typography>
            {t('dashboard.nodeFooter.soon')}
          </Typography>
        </BoostBannerPanel>
        <BoostBannerPanel
          style={{
            border: '1px solid',
            backgroundColor: localStorage.getItem('mui-mode') === 'light' ? "#FFFFFF" : "#262527",
            borderColor: localStorage.getItem('mui-mode') === 'light' ? "#E4E2E4" : "#474648"
          }}
        >
          <Typography>
            {t('dashboard.nodeFooter.saas')}
          </Typography>
          <Typography>
            {t('dashboard.nodeFooter.saasShare')}
          </Typography>
          <Typography>
            {t('dashboard.nodeFooter.soon')}
          </Typography>
        </BoostBannerPanel>
        <BoostBannerPanel
          style={{
            border: '1px solid',
            backgroundColor: localStorage.getItem('mui-mode') === 'light' ? "#FFFFFF" : "#262527",
            borderColor: localStorage.getItem('mui-mode') === 'light' ? "#E4E2E4" : "#474648"
          }}
        >
          <Typography>
            {t('dashboard.nodeFooter.storage')}
          </Typography>
          <Typography>
            {t('dashboard.nodeFooter.storageShare')}
          </Typography>
          <Typography>
            {t('dashboard.nodeFooter.soon')}
          </Typography>
        </BoostBannerPanel>
      </Stack>

      <Typography
        fontSize="38px"
        lineHeight="3rem"
        maxWidth="28rem"
        textAlign="center"
        mt="1rem"
      >
        {t('dashboard.nodeFooter.boost')}
      </Typography>

      <Stack width="100%" maxWidth="lg" margin="auto" alignItems={'center'} marginBottom="40px">
        <BoostBannerPanelRocket
          style={{
            border: '1px solid',
            backgroundColor: localStorage.getItem('mui-mode') === 'light' ? "#FFFFFF" : "#262527",
            borderColor: localStorage.getItem('mui-mode') === 'light' ? "#E4E2E4" : "#474648"
          }}
          // spacing={4}
          direction={{ xs: "column", sm: "row" }}
          padding="1.5rem"
          borderRadius="1rem"
          // justifyContent="center"
          width="100%"
          maxWidth="1040px"
        >
          <Stack
            sx={{ textAlign: "center" }}
            alignItems="flex-start"
            maxHeight="10.6rem"
            flex="1"
          // paddingLeft={{ xs: `4rem`, sm: undefined }}
          >
            <RocketImage src={rocketPlatformPNG} />
          </Stack>
          <Stack flex="1">
            <Typography color={colors.primary}>
              {t('dashboard.nodeFooter.boost')}
            </Typography>
            <Typography variant="h6" sx={{ paddingBottom: "1rem" }}>
              {t('dashboard.nodeFooter.boostStalking')}
            </Typography>
            <Stack flex="1" justifyContent="flex-end">
              <Typography
                fontSize="12px"
                textTransform="uppercase"
                sx={{ opacity: `0.7` }}
              >
                {t('dashboard.nodeFooter.soon')}
              </Typography>
            </Stack>
          </Stack>
        </BoostBannerPanelRocket>
      </Stack>
    </BoostBannerWrapper>
  );
};
