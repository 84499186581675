import Typography from "@mui/material/Typography";
import { useIntl } from "react-intl";
import Stack from "@mui/material/Stack";
import { useState, useEffect } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { testLocalServer } from "../../../API/index";
import useAppState from "../../../store/appState/useAppState";
import { Grid } from "@mui/material";
import {
	DaemonDownloadButton,
	DaemonDownloadIconWrapper,
	DaemonDownloadLabel,
	DaemonDownloadWrapper, PageContent
} from "./styles";
import { RiArrowDownCircleLine } from "react-icons/ri";
import { LiaApple, LiaWindows } from "react-icons/lia";
import { VscGithub } from "react-icons/vsc";
import { GoArrowUpRight } from "react-icons/go";


import allWhite from './assets/all-white.svg';
import allBlack from './assets/all-black.svg';
import allBlue from './assets/all-blue.svg';
import fireBlue from './assets/newest-blue.svg';
import fireWhite from './assets/newest-white.svg';
import fireBlack from './assets/newest-black.svg';
import playWhite from './assets/play-white.svg';
import playBlue from './assets/play-blue.svg';
import playBlack from './assets/play-black.svg';
import mediaWhite from './assets/mediaWhite.svg';
import mediaBlack from './assets/mediaBlack.svg';
import mediaBlue from './assets/mediaBlue.svg';
import petWhite from './assets/pet-white.svg';
import petBlack from './assets/pet-black.svg';
import petBlue from './assets/pet-blue.svg';
import miningWhite from './assets/mining-white.svg';
import miningBlack from './assets/mining-black.svg';
import miningBlue from './assets/mining-blue.svg';
import csWhite from './assets/cs-white.svg';
import csBlack from './assets/cs-black.svg';
import csBlue from './assets/cs-blue.svg';

import conetianBg from './assets/conetian-game.svg';
import fx168bg from './assets/fx168bg.svg';
import silentProxy from './assets/silent-proxy.svg';
import silentVpn from './assets/silent-vpn.svg';
import messageBg from './assets/messagebg.svg';
import storageBg from './assets/storagebg.svg';
import dApps1 from './assets/dApps1.svg';
import dApps2 from './assets/dApps2.svg';
import dApps3 from './assets/dApps3.svg';
import conetProxy from './assets/conet-proxy.svg';
import walletMining from './assets/wallet-mining.svg';

import DiscoverButtom from "./components/DiscoverButtom";
import AppCard, { IAppCardsProps } from "./components/AppCard";
import { useTranslation } from "react-i18next";
import { Button } from '@mui/base';
import { ArrowForwardRounded } from '@mui/icons-material';

interface IDiscoverButtom {
	name: string;
	coloredImg: string;
	whiteImg: string;
	blackImg: string;
	tag: string;
}

const downloadConet = (newValue: any) => {
	switch (newValue) {
		default:
		case 0: {
			return window.open(
				`https://github.com/CoNET-project/seguro-platform/releases/download/0.0.1/CONET-0.61.0.exe`
			);
		}
		case 1: {
			return window.open(
				`https://github.com/CoNET-project/seguro-platform/releases/download/0.0.1/CONET-0.61.0-Apple-M-Series-cpu.dmg`
			);
		}
		case 2: {
			return window.open(
				`https://github.com/CoNET-project/seguro-platform/releases/download/0.0.1/CONET-0.61.0-Inter-CPU.dmg`
			);
		}
		case 3: {
			return window.open(
				`https://github.com/CoNET-project/seguro-platform/releases/tag/0.0.1`
			);
		}
	}
};

export const DownloadArea = () => {
	const intl = useIntl();
	return (
		<Grid container spacing={1} columns={{ xs: 4, sm: 8, md: 12 }}>
			<Typography
				variant="h6"
				sx={{
					textAlign: "center",
					fontSize: "1rem",
					width: `100%`,
					padding: `1.45rem 0 0.65rem`,
					fontWeight: `500`,
				}}
			>
				{intl.formatMessage({ id: "platform.api.daemon.downloadDetail" })}
			</Typography>
			<Grid item md={12} sm={8} xs={4} sx={{ textAlign: "center" }}>
				<DaemonDownloadWrapper>
					<DaemonDownloadButton onClick={() => downloadConet(0)}>
						<DaemonDownloadLabel>
							<LiaWindows fontSize="1.85rem" />
							Windows
						</DaemonDownloadLabel>
						<DaemonDownloadIconWrapper>
							<RiArrowDownCircleLine />
						</DaemonDownloadIconWrapper>
					</DaemonDownloadButton>

					<DaemonDownloadButton onClick={() => downloadConet(1)}>
						<DaemonDownloadLabel>
							<LiaApple fontSize="1.85rem" />
							Apple M
						</DaemonDownloadLabel>
						<DaemonDownloadIconWrapper>
							<RiArrowDownCircleLine />
						</DaemonDownloadIconWrapper>
					</DaemonDownloadButton>

					<DaemonDownloadButton onClick={() => downloadConet(2)}>
						<DaemonDownloadLabel>
							<LiaApple fontSize="1.85rem" />
							Apple
						</DaemonDownloadLabel>
						<DaemonDownloadIconWrapper>
							<RiArrowDownCircleLine />
						</DaemonDownloadIconWrapper>
					</DaemonDownloadButton>

					<DaemonDownloadButton onClick={() => downloadConet(3)}>
						<DaemonDownloadLabel>
							<VscGithub fontSize="1.5rem" /> More
						</DaemonDownloadLabel>
						<DaemonDownloadIconWrapper>
							<GoArrowUpRight />
						</DaemonDownloadIconWrapper>
					</DaemonDownloadButton>
				</DaemonDownloadWrapper>
			</Grid>
		</Grid>
	);
};


const deamon = () => {

	const { t } = useTranslation()
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const dApps: IAppCardsProps[] = [
		{
			title: t('dApps.dApps.conetianTelegram.title'),
			description: t('dApps.dApps.conetianTelegram.description'),
			appImageBlack: conetianBg,
			appImageWhite: conetianBg,
			appUrl: 'https://t.me/conetian_bot',
			soon: false,
			renderTags: [t('dApps.discoverButton.gaming')],
			tags: ['gaming', 'mining']
		},
		{
			title: t('dApps.dApps.conetianWeb.title'),
			description: t('dApps.dApps.conetianWeb.description'),
			appImageBlack: conetianBg,
			appImageWhite: conetianBg,
			appUrl: 'https://theconetian.com/',
			soon: false,
			tags: ['gaming', 'mining'],
			renderTags: [t('dApps.discoverButton.gaming')]
		},
		{
			title: t('dApps.dApps.proxyExtension.title'),
			description: t('dApps.dApps.proxyExtension.description'),
			appImageWhite: conetProxy,
			appImageBlack: conetProxy,
			appUrl: 'https://chromewebstore.google.com/detail/conet-platform-extension/pkhkeliikkihkaolfcogfcbjmbehockd',
			soon: false,
			renderTags: [t('dApps.discoverButton.tool')],
			tags: ['tool']
		},
		{
			title: t('dApps.dApps.miningExtension.title'),
			description: t('dApps.dApps.miningExtension.description'),
			appImageWhite: walletMining,
			appImageBlack: walletMining,
			appUrl: 'https://chromewebstore.google.com/detail/wallet-mining-extension/ffccpciacnkgkkddhanggjlcajbbmmmn',
			soon: false,
			renderTags: [t('dApps.discoverButton.tool')],
			tags: ['tool', 'mining']
		},
		{
			title: t('dApps.dApps.fx168.title'),
			description: t('dApps.dApps.fx168.description'),
			appImageWhite: fx168bg,
			appImageBlack: fx168bg,
			appUrl: 'https://apps.apple.com/ca/app/fx168%E8%B4%A2%E7%BB%8F-%E5%AE%9E%E6%97%B6%E6%B7%B1%E5%BA%A6%E7%BE%8E%E8%82%A1%E8%B5%84%E8%AE%AF%E5%8F%8A%E5%9B%BD%E9%99%85%E6%97%B6%E6%94%BF%E6%96%B0%E9%97%BB/id535352246',
			soon: false,
			renderTags: [t('dApps.discoverButton.media')],
			tags: ['media', 'mining']
		},
		{
			title: t('dApps.dApps.silentProxy.title'),
			description: t('dApps.dApps.silentProxy.description'),
			appImageWhite: silentProxy,
			appImageBlack: silentProxy,
			appUrl: 'https://github.com/CoNET-project/seguro-platform/releases/tag/0.0.1',
			soon: false,
			renderTags: [t('dApps.discoverButton.tool')],
			tags: ['tool',]
		},
		{
			title: t('dApps.dApps.silentVpn.title'),
			description: t('dApps.dApps.silentVpn.description'),
			appImageWhite: silentVpn,
			appImageBlack: silentVpn,
			appUrl: undefined,
			soon: true,
			renderTags: [t('dApps.discoverButton.tool')],
			tags: ['tool', 'mining', 'cs', 'pet'],
		},
		{
			title: t('dApps.dApps.conetianMessenger.title'),
			description: t('dApps.dApps.conetianMessenger.description'),
			appImageWhite: messageBg,
			appImageBlack: messageBg,
			appUrl: undefined,
			soon: true,
			renderTags: [t('dApps.discoverButton.communication')],
			tags: ['communication', 'cs', 'pet'],
		},
		{
			title: t('dApps.dApps.conetianStorage.title'),
			description: t('dApps.dApps.conetianStorage.description'),
			appImageWhite: storageBg,
			appImageBlack: storageBg,
			appUrl: undefined,
			soon: true,
			renderTags: [t('dApps.discoverButton.tool')],
			tags: ['tool', 'cs', 'pet'],
		},
	]

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [verLow, setVerLow] = useState(false);
	const { setlocalDaemon } = useAppState();
	const [selectedDiscover, setSelectedDiscover] = useState<string>('all')
	const [filteredApps, setFilteredApps] = useState<IAppCardsProps[]>(dApps)



	const DiscoverButtons: IDiscoverButtom[] = [
		{
			name: t('dApps.discoverButton.all'),
			coloredImg: allBlue,
			whiteImg: allWhite,
			blackImg: allBlack,
			tag: 'all'
		},
		{
			name: t('dApps.discoverButton.newest'),
			coloredImg: fireBlue,
			whiteImg: fireWhite,
			blackImg: fireBlack,
			tag: 'newest'
		},
		{
			name: t('dApps.discoverButton.cs'),
			coloredImg: csBlue,
			whiteImg: csWhite,
			blackImg: csBlack,
			tag: 'cs'
		},
		{
			name: t('dApps.discoverButton.mining'),
			coloredImg: miningBlue,
			whiteImg: miningWhite,
			blackImg: miningBlack,
			tag: 'mining'
		},
		{
			name: t('dApps.discoverButton.pet'),
			coloredImg: petBlue,
			whiteImg: petWhite,
			blackImg: petBlack,
			tag: 'pet'
		},
		{
			name: t('dApps.discoverButton.media'),
			coloredImg: mediaBlue,
			whiteImg: mediaWhite,
			blackImg: mediaBlack,
			tag: 'media'
		},
		{
			name: t('dApps.discoverButton.gaming'),
			coloredImg: playBlue,
			whiteImg: playWhite,
			blackImg: playBlack,
			tag: 'gaming'
		},
	]

	function filterApps(tag: string) {
		if (tag === 'all') {
			const filteredApps = [...dApps]
			setFilteredApps(filteredApps);
			return
		}


		if (tag === 'newest') {
			const filteredApps = dApps.filter((app) => !app.tags.includes('cs'))
			setFilteredApps(filteredApps);
			return
		}

		setFilteredApps(dApps.filter((app) => app.tags.includes(tag)))
	}

	useEffect(() => {
		const fetchData = async () => {
			if (!active) {
				return;
			}

			const testDeamon = async () => {
				testClisk();
			};

			testDeamon().catch((ex) => {
				console.log(`APP useEffect testDeamon error`, ex);
			});
		};

		let active = true;
		fetchData();
		return () => {
			active = false;
		};
	}, []);

	const testClisk = async () => {
		const fetchData = async () => {
			if (!active) {
				return;
			}
			setLoading(true);

			const test = await testLocalServer();
			setLoading(false);
			if (test !== true) {
				setError(true);
				if (test === false) {
					setVerLow(true);
				}
				return setTimeout(() => setError(false), 3000);
			}
			setlocalDaemon(true);
		};

		let active = true;
		fetchData();
		return () => {
			active = false;
		};
	};

	return (
		<Stack
			direction="row"
			justifyContent='center'
			alignItems="center"
			alignSelf={'flex-start'}
			width={['100%', '100%', 'calc(100% - 140px)']}
			paddingRight={['20px', '20px', '70px']}
			paddingLeft={['20px', '20px', '70px']}
			margin={'0 auto'}
		>
			<PageContent>
				<Box display={'flex'} gap={1} mb={4}>
					<Typography fontWeight={700} fontSize={'36px'}>{t('dApps.titleWhite')}</Typography>
					<Typography fontWeight={700} fontSize={'36px'} color={'#8DA8FF'}>{t('dApps.titleBlue')}</Typography>
				</Box>

				<Box
					borderRadius={8} style={{ background: 'linear-gradient(93.49deg, #4E72B6 0%, #9FBFE5 100%)' }}
					padding="40px" gap="20px"
					display="flex" justifyContent="space-between" alignItems={isMobile ? "flex-start" : "flex-end"} flexDirection={isMobile ? "column" : "row"}
				>
					<Box maxWidth={['200px', '420px']}>
						<Typography mb={2} fontWeight={700} fontSize={[22, '36px']} color={'#fff'}>{t('dApps.silentPassTitle')}</Typography>
						<Typography fontSize={[12, '16px']} color={'#fff'}>{t('dApps.silentPassSubTitle')}</Typography>
					</Box>
					<Box alignSelf="flex-end">
						<Button
							style={{ background: "#4E72B6", color: "#ffffff", padding: "12px 20px", border: "none", borderRadius: "16px", display: "flex", alignItems: "center", gap: "16px" }}
							onClick={() => window.open('https://chromewebstore.google.com/detail/conet-platform-extension/pkhkeliikkihkaolfcogfcbjmbehockd?hl=pt-br', '_blank')}
						>
							{t('dApps.silentPassButton')}
							<ArrowForwardRounded />
						</Button>
					</Box>
				</Box>

				<Box justifyContent='center' display='flex' flexDirection='column' gap={'16px'}>
					<Typography fontWeight={700} fontSize={'24px'} mt={6}>{t('dApps.popular')}</Typography>

					<Stack display={'flex'} flexDirection={'row'} flexWrap={'wrap'} gap={2} mb={6} width='100%' justifyContent={['center', 'center', 'center', 'flex-start']}>
						{dApps.slice(0, 4).map((d, i) => {
							return (<AppCard key={i} appImageWhite={d.appImageWhite} appImageBlack={d.appImageBlack} appUrl={d.appUrl} soon={d.soon} tags={d.tags} title={d.title} description={d.description} renderTags={d.renderTags} />)
						})}
					</Stack>
				</Box>

				<Box justifyContent='center' display='flex' flexDirection='column' mb={10}>
					<Typography fontWeight={700} fontSize={'24px'} mb={'8px'}>{t('dApps.discover')}</Typography>

					<Stack display={'flex'} flexDirection={'row'} gap={2} padding={'20px 0'} overflow={['scroll', 'scroll', 'scroll', 'auto']} style={{ overflowY: 'hidden', scrollbarWidth: 'thin' }} mb={'32px'}>
						{
							DiscoverButtons.map((d: IDiscoverButtom, i) => {
								return (<DiscoverButtom key={i} filterApps={filterApps} whiteImg={d.whiteImg} blackImg={d.blackImg} selectedDiscover={selectedDiscover} setSelectedDiscover={setSelectedDiscover} selectedImg={d.coloredImg} tag={d.tag} title={d.name} />)
							})
						}
					</Stack>

					<Stack display={'flex'} flexDirection={'row'} flexWrap={'wrap'} gap={2} width='100%' justifyContent={['center', 'center', 'center', 'flex-start']}>
						{filteredApps.map((d, i) => {
							return (<AppCard key={i} appImageWhite={d.appImageWhite} appImageBlack={d.appImageBlack} appUrl={d.appUrl} soon={d.soon} tags={d.tags} title={d.title} description={d.description} renderTags={d.renderTags} />)
						})}
					</Stack>
				</Box>

				<Box display={['none', 'flex']} flexDirection={'column'} justifyContent={'center'} alignContent={'center'} alignItems={'center'}>

					<hr style={{ width: '100%', color: '#474648', opacity: '0.5' }} />

					<Box display={'flex'} py={5} justifyContent={'center'} alignItems={'center'} gap={'-100px'} width={'70vw'}>
						<div style={{
							backgroundImage: `url(${dApps1})`,
							backgroundSize: 'contain',
							backgroundPosition: 'center',
							backgroundRepeat: 'no-repeat',
							width: '25%',
							height: '320px'
						}}></div>

						<div style={{
							backgroundImage: `url(${dApps2})`,
							backgroundSize: 'contain',
							backgroundPosition: 'center',
							backgroundRepeat: 'no-repeat',
							width: '80%',
							height: '400px'
						}}></div>

						<div style={{
							backgroundImage: `url(${dApps3})`,
							backgroundSize: 'contain',
							backgroundPosition: 'center',
							backgroundRepeat: 'no-repeat',
							width: '25%',
							height: '320px'
						}}></div>

					</Box>

					<Box width={'550px'} mb={10}>
						<Typography color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#fff"} fontWeight={700}>
							{t('dApps.bringTitle')}
						</Typography>
						<Typography color={'#858586'}>
							{t('dApps.bringSubTitle')}
						</Typography>
					</Box>

					<hr style={{ width: '100%', color: '#474648', opacity: '0.5' }} />
				</Box>

			</PageContent>
		</Stack>
	);
};

export default deamon;
